import CardContent from "@mui/material/CardContent";
import { MDBDataTable } from "mdbreact";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "@mui/material";
import { fetchBusinessCardTemplates } from "../../../../store/BusinessCardTemplates/features";

const BannerPreview = styled.img`
  height: 50px;
  width: 150px;
  object-fit: contain;
`;

export default function BusinessCardTemplatesTable({
  setSelectedTemplate,
  setBusinesCardTemplateModalOpen,
}) {
  const [dataTable, setDataTable] = useState([]);
  const businessCardTemplates = useSelector(
    (state) => state.businessCardTemplates.value
  );
  const dispatch = useDispatch();

  const fetchData = () => {
    const columnNames = ["Template", "Name", "Action"];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (businessCardTemplates[0]) {
      rows = businessCardTemplates.map((template) => {
        return {
          template: template.image ? (
            <BannerPreview src={template.image} alt="Template" />
          ) : (
            ""
          ),
          name: template.slug,
          action: (
            <Button
              onClick={() => {
                setSelectedTemplate(template);
                setBusinesCardTemplateModalOpen(true);
              }}
            >
              EDIT
            </Button>
          ),
        };
      });
    }
    setDataTable({ columns, rows });
  };

  useEffect(() => {
    dispatch(fetchBusinessCardTemplates());
  }, []);

  useEffect(() => {
    fetchData();
  }, [businessCardTemplates]);

  return (
    <>
      <CardContent>
        <MDBDataTable
          striped
          entriesOptions={[10, 20, 50]}
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "of", "results"]}
          sortable
          searchLabel={"Search"}
          noRecordsFoundLabel={"Nothing found here"}
          paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
          paginationLabel={[
            <div
              style={{
                cursor: "pointer",
              }}
            >
              Previous
            </div>,
            <div
              style={{
                cursor: "pointer",
              }}
            >
              Next
            </div>,
          ]}
          info={true}
          entriesLabel={"Show results"}
          data={dataTable}
          sortRows={["template"]}
        />
      </CardContent>
    </>
  );
}
