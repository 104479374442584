import React from "react";
import { Modale } from "../../../../components/Modale";
import styled from "styled-components";

const Container = styled.div`
  background-color: rgba(243, 243, 243);
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
`;
const WrapperTitleQuestion = styled.div`
  display: flex;
`;
const Title = styled.span`
  font-weight: bold;
  margin-bottom: 0px;
  text-decoration: underline;
`;
const Question = styled.p`
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 8px;
`;

const Note = styled.span`
  font-weight: bold;
  margin-bottom: 0px;
`;

const ModalRate = ({ selectedRate, setSelectedRate }) => {
  return (
    <>
      <Modale
        title={"View the evaluation details"}
        isOpen={selectedRate !== null}
        close={() => setSelectedRate(null)}
        actions={[]}
      >
        {selectedRate !== null &&
          selectedRate.data.map((element) => (
            <Container key={element.title}>
              <WrapperTitleQuestion>
                <Title>{element.title}:</Title>
                <Question>{element.question}</Question>
              </WrapperTitleQuestion>
              <Note>{element.note} / 5</Note>
            </Container>
          ))}
      </Modale>
    </>
  );
};
export default ModalRate;
