import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { deleteDeviceById } from "../../../../../modules/taxiDevices";
import { fetchTaxiDevices } from "../../../../../store/TaxiDevices/features";
import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { CheckBox } from "../../../../../components/CheckBox";
import { useState } from "react";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: no-wrap;
  font-weight: bold;
`;

const Fields = styled.div`
  width: 50%;
`;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

export default function DeviceSettings({
  selectedDevice,
  setSelectedDevice,
  handleSaveDevice,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteDevice = async () => {
    await deleteDeviceById(selectedDevice.id);
    dispatch(fetchTaxiDevices());
    setIsDeleteModalOpen(false);
    navigate("/dashboard/taxis");
  };

  return (
    <>
      <ConfirmationModal
        title="Delete device"
        content={`Do you realy want to delete «${selectedDevice.name}» ?`}
        isOpen={isDeleteModalOpen}
        close={() => setIsDeleteModalOpen(false)}
        onConfirmation={async () => {
          handleDeleteDevice();
        }}
      />
      <section>
        <Container>
          <Fields>
            <LabelInput htmlFor="adminPassword">
              Admin password
              <Input
                type="password"
                id="adminPassword"
                placeholder="Define a password"
                value={selectedDevice.adminPassword}
                onChange={(e) =>
                  setSelectedDevice({
                    ...selectedDevice,
                    adminPassword: e.target.value,
                  })
                }
                name="adminPassword"
              />
            </LabelInput>
            <LabelInput htmlFor="account">
              Account Id
              <Input
                type="text"
                id="account"
                placeholder="Enter an account id"
                value={selectedDevice.accountUid || ""}
                onChange={(e) =>
                  setSelectedDevice({
                    ...selectedDevice,
                    accountUid: e.target.value,
                  })
                }
                name="account"
              />
            </LabelInput>
          </Fields>
          <Fields style={{ paddingTop: "30px" }}>
            <CheckBox
              title="Test device"
              checked={selectedDevice.testDevice}
              onChange={(e) =>
                setSelectedDevice({
                  ...selectedDevice,
                  testDevice: e.target.checked,
                })
              }
            />
          </Fields>
        </Container>
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis")}>Back</Button>
          <Button onClick={() => setIsDeleteModalOpen(true)}>Delete</Button>
          <Button onClick={() => handleSaveDevice()}>Save</Button>
        </Buttons>
      </section>
    </>
  );
}
