import { useState } from "react";
import { GroupsTable } from "./components/GroupsTable";
import { DevicesTable } from "./components/DevicesTable";
import { CampaignsTable } from "./components/CampaignsTable";
import { GroupModal } from "./components/GroupModal";
import { DeviceModal } from "./components/DeviceModal";
import { CardWithTabs } from "../../../components/CardWithTabs";
import { ModuleModal } from "./components/ModuleModal";
import { ModulesTable } from "./components/ModulesTable";
import { ModulesPreviewModal } from "./components/ModulesPreviewModal";
import { useSelector } from "react-redux";

export default function Taxis() {
  const selectedModuleGroupJson = window.localStorage.getItem(
    "selectedModuleGroup"
  );
  const selectedModuleGroupStorage =
    selectedModuleGroupJson !== "undefined"
      ? JSON.parse(selectedModuleGroupJson)
      : null;
  const moduleGroups = useSelector((state) => state.taxiModuleGroups.value);
  const [selectedModuleGroup, _setSelectedModuleGroup] = useState(
    selectedModuleGroupStorage || moduleGroups?.[0]
  );
  const setSelectedModuleGroup = (state) => {
    if (!state) return;
    _setSelectedModuleGroup(state);
    window.localStorage.setItem("selectedModuleGroup", JSON.stringify(state));
  };
  const [modalGroup, setModalGroup] = useState(false);
  const [modalDevice, setModalDevice] = useState(false);
  const [modalModule, setModalModule] = useState(false);
  const [createCampaign, setCreateCampaign] = useState(false);
  const [modulesPreviewModalOpen, setModulesPreviewModalOpen] = useState(false);
  const userRole = JSON.parse(sessionStorage.getItem("user"))?.role;
  const [groupId, setGroupId] = useState(null);

  const tabs = [
    {
      name: "Devices",
      component: <DevicesTable />,
      buttons:
        userRole === "admin"
          ? [
              {
                name: "ADD A DEVICE",
                action: () => setModalDevice(true),
              },
            ]
          : [],
      roles: ["admin", "vendor", "taxi"],
    },
    {
      name: "Groups",
      component: <GroupsTable />,
      buttons: [
        {
          name: "ADD A GROUP",
          action: () => setModalGroup(true),
        },
      ],
      roles: ["admin"],
    },
    {
      name: "Campaigns",
      component: <CampaignsTable createCampaign={createCampaign} />,
      buttons: [
        {
          name: "ADD A Campaign",
          action: () => setCreateCampaign(true),
        },
      ],
      roles: ["admin"],
    },
    {
      name: "Modules",
      component: (
        <ModulesTable
          moduleGroups={moduleGroups}
          selectedModuleGroup={selectedModuleGroup}
          setSelectedModuleGroup={setSelectedModuleGroup}
          setGroupId={setGroupId}
        />
      ),
      buttons:
        userRole === "admin"
          ? [
              {
                name: "CREATE A MODULE",
                action: () => setModalModule(true),
              },
              {
                name: "PREVIEW",
                action: (id) => {
                  setModulesPreviewModalOpen(true);
                },
              },
            ]
          : [],
      roles: ["admin", "vendor", "sponsor"],
    },
  ];
  if (!selectedModuleGroup) return null;
  
  return (
    <>
      <GroupModal isOpen={modalGroup} close={() => setModalGroup(false)} />
      <DeviceModal isOpen={modalDevice} close={() => setModalDevice(false)} />
      <ModuleModal
        isOpen={modalModule}
        close={() => setModalModule(false)}
        selectedModuleGroup={selectedModuleGroup}
      />
      <ModulesPreviewModal
        isOpen={modulesPreviewModalOpen}
        close={() => {
          setGroupId(null);
          setModulesPreviewModalOpen(false);
        }}
        groupId={selectedModuleGroup.id}
      />
      <CardWithTabs name={"Taxis"} tabs={tabs} />
    </>
  );
}
