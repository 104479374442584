import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllModuleGroups } from "../../modules/taxiModuleGroups";

export const fetchTaxiModuleGroups = createAsyncThunk(
  "taxiModules/fetchTaxiModuleGroups",
  async (arg, thunkAPI) => {
    try {
      const response = await getAllModuleGroups();
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
