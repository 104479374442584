import { useCallback, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCampaigns } from "../../../../store/Campaigns/features";
import { campaignsSelector } from "../../../../store/Campaigns";
import { fetchAllAnalyticsCampaigns } from "../../../../store/AnalyticsCampaigns/features";
import { analyticsCampaignsSelector } from "../../../../store/AnalyticsCampaigns";
import { Status } from "../../../../components/Status";
import { Modale } from "../../../../components/Modale";
import {
  deleteCampaignById,
  updateCampaignById,
} from "../../../../modules/campaigns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

const StyledDataTable = styled.div`
  tr:hover {
    cursor: pointer;
  }
`;

const getStatus = (status) => {
  if (status === "accepted" || status === "inprogress") return "accepted";
  return status;
};

export function CampaignsTable({ createCampaign }) {
  const dispatch = useDispatch();
  const { campaigns, isLoading } = useSelector(campaignsSelector);
  const { analyticsCampaigns } = useSelector(analyticsCampaignsSelector);
  const [dataTable, setDataTable] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const navigate = useNavigate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const getCtr = (campaignId) => {
    const viewCampaign = analyticsCampaigns.filter(
      (analytic) =>
        analytic.type === "view" && analytic.campaignId === campaignId
    ).length;
    const clickCampaign = analyticsCampaigns.filter(
      (analytic) =>
        analytic.type === "click" && analytic.campaignId === campaignId
    ).length;
    const ctr = (clickCampaign / viewCampaign) * 100 || 0;
    return ctr.toFixed(0) + "%";
  };

  const fetchData = useCallback(() => {
    const columnNames = [
      "Status",
      "Name",
      "Start_Date",
      "End_Date",
      "Views",
      "Scans",
      "Clicks",
      "CTR",
      "Action",
    ];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (campaigns.length > 0) {
      campaigns.map((item, id) => {
        return rows.push({
          id,
          status: (
            <Status status={getStatus(item.status)}>{item.status}</Status>
          ),
          name: item.name,
          start_date: item.startDate
            ? format(item.startDate, "yyyy/MM/dd")
            : "No date",
          end_date: item.endDate
            ? format(item.endDate, "yyyy/MM/dd")
            : "No date",
          views: analyticsCampaigns
            .filter((analytics) => analytics.type === "view")
            .filter((analytics) => analytics.campaignId === item.id).length,
          scans: (
            <small>
              <img
                src="https://img.icons8.com/windows/15/000000/video.png"
                alt="icon-video"
              />{" "}
              {
                analyticsCampaigns
                  .filter(
                    (analytics) =>
                      analytics.mediaType === "VIDEO" &&
                      analytics.type === "qrcode"
                  )
                  .filter((analytics) => analytics.campaignId === item.id)
                  .length
              }{" "}
              <img
                src="https://img.icons8.com/material-outlined/15/000000/picture.png"
                alt="icon-image"
              />{" "}
              {
                analyticsCampaigns
                  .filter(
                    (analytics) =>
                      analytics.mediaType === "BANNER" &&
                      analytics.type === "qrcode"
                  )
                  .filter((analytics) => analytics.campaignId === item.id)
                  .length
              }{" "}
            </small>
          ),
          clicks: (
            <small>
              <img
                src="https://img.icons8.com/windows/15/000000/video.png"
                alt="icon-video"
              />{" "}
              {
                analyticsCampaigns
                  .filter(
                    (analytics) =>
                      analytics.mediaType === "VIDEO" &&
                      analytics.type === "click"
                  )
                  .filter((analytics) => analytics.campaignId === item.id)
                  .length
              }{" "}
              <img
                src="https://img.icons8.com/material-outlined/15/000000/picture.png"
                alt="icon-image"
              />{" "}
              {
                analyticsCampaigns
                  .filter(
                    (analytics) =>
                      analytics.mediaType === "BANNER" &&
                      analytics.type === "click"
                  )
                  .filter((analytics) => analytics.campaignId === item.id)
                  .length
              }{" "}
            </small>
          ),
          ctr: getCtr(item.id),
          action: (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCampaign(item);
              }}
              variant={"contained"}
            >
              Action
            </Button>
          ),
          clickEvent: () => navigate("/dashboard/taxis/campaign/" + item.id),
        });
      });
    }
    setDataTable({ columns, rows });
  }, [campaigns]);

  useEffect(() => {
    dispatch(fetchAllCampaigns());
    dispatch(fetchAllAnalyticsCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) fetchData();
  }, [isLoading, fetchData]);

  useEffect(() => {
    if (createCampaign) return navigate("/dashboard/taxis/campaign/create");
  }, [createCampaign]);

  const onUpdateCampaignById = async (data) => {
    if (selectedCampaign.status === "inprogress") {
      return toast.error(
        "You cannot change the status of a campaign in progress."
      );
    }
    await updateCampaignById(selectedCampaign.id, data);
    dispatch(fetchAllCampaigns());
    return setSelectedCampaign(null);
  };

  const onDeleteCampaign = async () => {
    await deleteCampaignById(selectedCampaign.id);
    dispatch(fetchAllCampaigns());
    setSelectedCampaign(null);
  };

  return (
    <>
      <Modale
        title="Change campaign status"
        content="Do you want to accept this campaign ?"
        isOpen={Boolean(selectedCampaign)}
        close={() => setSelectedCampaign(null)}
        actions={[
          {
            title: "Delete",
            click: () => {
              setIsConfirmationModalOpen(true);
            },
          },
          {
            title: "No",
            click: async () => {
              onUpdateCampaignById({ status: "refused" });
            },
          },
          {
            title: "Yes",
            click: async () => {
              onUpdateCampaignById({ status: "accepted" });
            },
          },
        ]}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        close={() => setIsConfirmationModalOpen(false)}
        title="Delete Campaign"
        content={`Do you really want to delete «${
          selectedCampaign?.name || ""
        }»`}
        onConfirmation={() => onDeleteCampaign()}
      />
      <CardContent>
        <StyledDataTable>
          <MDBDataTable
            striped
            entriesOptions={[10, 20, 50]}
            entries={10}
            noBottomColumns
            infoLabel={["", "-", "of", "results"]}
            sortable
            searchLabel={"Search"}
            noRecordsFoundLabel={"Nothing found here"}
            paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
            paginationLabel={[
              <div style={{ cursor: "pointer" }}>Previous</div>,
              <div style={{ cursor: "pointer" }}>Next</div>,
            ]}
            info={true}
            entriesLabel={"Show results"}
            data={dataTable}
            sortRows={["usedAt"]}
          />
        </StyledDataTable>
      </CardContent>
    </>
  );
}
