import { functionsFetch } from "../utils/functionsFetch";

export const createModuleGroup = async (name) => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/group/`,
      data: { name },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const duplicateModuleGroup = async (id) => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/group/${id}`,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllModuleGroups = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/group/`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateModuleGroupById = async ({ id, name }) => {
  try {
    await functionsFetch({
      method: "PUT",
      url: `/taxi-modules/group/${id}`,
      data: { name },
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const deleteModuleGroupById = async (id) => {
  try {
    await functionsFetch({
      method: "DELETE",
      url: `/taxi-modules/group/${id}`,
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};
