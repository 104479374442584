import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllBusinessCardTemplates } from "../../modules/businessCardTemplates";

export const fetchBusinessCardTemplates = createAsyncThunk(
  "businessCardTemplates/fetchBusinessCardTemplates",
  async (arg, thunkAPI) => {
    try {
      const response = await getAllBusinessCardTemplates();
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
