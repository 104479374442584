import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxideviceShop } from "./features";

export const taxiDevicesShop = createSlice({
    name: "taxiDevicesShop",
    initialState: {
        allProductsDevices: [],
        loading: true,
        error: true,
    },
    reducers: {},
    extraReducers: builder => {
        builder
    .addCase(fetchTaxideviceShop.fulfilled, (state, { payload }) => {
        state.allProductsDevices = payload;
        state.loading = false;
        state.error = false;
    })
    .addCase(fetchTaxideviceShop.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
    })
    .addCase(fetchTaxideviceShop.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
    });
},
});

export default taxiDevicesShop.reducer;

export const taxiDevicesShopSelector = state => state.taxiDevicesShop;