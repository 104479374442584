import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllListingItems } from "../../modules/taxiModuleListingItems";

export const fetchAllListingItems = createAsyncThunk(
  "taxiModuleListingItem/fetchAllListingItems",
  async (arg, thunkAPI) => {
    try {
      const responseData = await getAllListingItems(arg);
      return responseData;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
