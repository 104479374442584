import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiModulesShop } from "./features";

export const taxiModulesShop = createSlice({
    name: "taxiModulesShop",
    initialState: {
        allProducts: [],
        loading: true,
        error: true,
    },
    reducers: {},
    extraReducers: builder => {
        builder
    .addCase(fetchTaxiModulesShop.fulfilled, (state, { payload }) => {
        state.allProducts = payload;
        state.loading = false;
        state.error = false;
    })
    .addCase(fetchTaxiModulesShop.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
    })
    .addCase(fetchTaxiModulesShop.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
    });
},
});

export default taxiModulesShop.reducer;

export const taxiModulesShopSelector = state => state.taxiModulesShop;