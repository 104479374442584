import { functionsFetch } from "../utils/functionsFetch";

export const createDevice = async device => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-devices/`,
      data: device,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllDevices = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-devices/`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateDeviceById = async ({ id, data }) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-devices/${id}`,
      data,
    });
    console.log(res.data);
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const deleteDeviceById = async id => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-devices/${id}`,
    });
    console.log(res.data);
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const createProductTaxi = async(deviceId, data) => {
  try {
    const res = await functionsFetch({
      method: "POST",
      url: `/taxi-devices/shop/${deviceId}/create`,
      data
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
