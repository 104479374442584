import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, CardContent, IconButton, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { fetchAllListingItems } from "../../../../../store/TaxiModuleListingItem/features";
import { DragAndDropTable } from "../../../../../components/DragAndDropTable";
import { updateListingItemById } from "../../../../../modules/taxiModuleListingItems";
import styled from "styled-components";
import { ModuleListingItemModal } from "./ModuleListingItemModal";

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
  justify-content: flex-end;
`;

export const ModuleListingItems = ({ selectedModule }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const nextUpdtateTime = useRef(Date.now());
  const listingItems = useSelector((state) => state.moduleListingItem.items);
  const [selectedListingItem, setSelectedListingItem] = useState(null);
  const [isLstingItemModalOpen, setIsListingItemModalOpen] = useState(false);

  //This will trigger handleUpdatelistingItemRanks function 2 seconds later from now
  const setupNextUpdate = (reorderedItems) => {
    nextUpdtateTime.current = Date.now() + 1990;
    setTimeout(async () => {
      await handleUpdateListingItemRanks(reorderedItems);
    }, "2000");
  };

  //This function will loop through items and save their ranks in listingItems database
  const handleUpdateListingItemRanks = async (reorderedItems) => {
    if (nextUpdtateTime.current <= Date.now()) {
      for (let i = 0; i < reorderedItems.length; i++) {
        const { id } = reorderedItems[i];
        const data = { rank: i + 1, id };
        await updateListingItemById(selectedModule.listingId, data);
      }
      dispatch(fetchAllListingItems(selectedModule.listingId));
      toast.success("Succesfuly updated items order.");
    }
  };

  //This is used to sort items in the table based on their rank
  const setOrderedItems = (rawListingItems) => {
    const orderedItems = [...rawListingItems].sort(function (a, b) {
      return a.rank - b.rank;
    });
    setItems(orderedItems);
  };

  const onAddItem = () => {
    setIsListingItemModalOpen(true);
  };

  const onEditItem = (item) => {
    setSelectedListingItem(item);
    setIsListingItemModalOpen(true);
  };

  useEffect(() => {
    if (selectedModule?.listingId) {
      dispatch(fetchAllListingItems(selectedModule.listingId));
    }
  }, [selectedModule]);

  useEffect(() => {
    setOrderedItems(listingItems);
  }, [listingItems, selectedModule]);

  return (
    <>
      <CardContent>
        <DragAndDropTable
          cols={["Index", "Banner", "Title", "Url", "Action"]}
          items={items.map((item, index) => {
            return {
              ...item,
              Index: index,
              Banner: (
                <img style={{ height: 50 }} src={item.banner} alt="banner" />
              ),
              Title: item.title,
              Url: item.url,
              Action: (
                <Button
                  onClick={() => onEditItem(item)}
                  variant={"contained"}
                  disabled={loading}
                >
                  EDIT
                </Button>
              ),
            };
          })}
          handleItemsMoved={(reorderedItems) => {
            setItems(reorderedItems);
            setupNextUpdate(reorderedItems);
          }}
        />
        <Buttons>
          <Button onClick={() => onAddItem()}>ADD ITEM</Button>
        </Buttons>
        <ModuleListingItemModal
          isOpen={isLstingItemModalOpen}
          setIsOpen={setIsListingItemModalOpen}
          selectedListingItem={selectedListingItem}
          setSelectedListingItem={setSelectedListingItem}
          listingId={selectedModule.listingId}
        />
      </CardContent>
    </>
  );
};
