import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CardWithTabs } from "../../../../components/CardWithTabs";
import { ModuleEdit } from "./components/ModuleEdit";
import { ModuleProducts } from "./components/ModuleProducts";
import { toast } from "react-toastify";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import { checkIsUrl } from "../../../../utils/util";
import { updateModuleById } from "../../../../modules/taxiModules";
import { ModuleAppearance } from "./components/ModuleAppearance";
import { ModuleSettings } from "./components/ModuleSettings";
import { ModuleListings } from "./components/ModuleListings";
import { ModuleListingItems } from "./components/ModuleListingItems";

export default function Module() {
  const { id } = useParams();
  const modules = useSelector((state) => state.taxiModules.value);
  const [selectedModule, setSelectedModule] = useState(
    modules.find((module) => module.id === id) || null
  );
  const userRole = JSON.parse(sessionStorage.getItem("user")).role;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSaveModule = async () => {
    if (checkValidity()) {
      await updateModuleById({
        id,
        data: { ...selectedModule, updatedAt: Date.now() },
      });
      dispatch(fetchTaxiModules());
      navigate("/dashboard/taxis?tab=modules");
    }
  };

  const checkValidity = () => {
    let errors = [];
    if (selectedModule.title.length < 3)
      errors.push("You should add a title with at least 3 characters");
    if (selectedModule.type === "qrcode") {
      if (!checkIsUrl(selectedModule.url))
        errors.push("You should add a valid url");
    }
    errors.forEach((error) => toast.error(error));
    return errors.length === 0;
  };

  const tabs = [
    {
      name: "Edit",
      component: (
        <ModuleEdit
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          handleSaveModule={handleSaveModule}
        />
      ),
      roles: ["admin"],
    },
    {
      name: "Appareance",
      component: (
        <ModuleAppearance
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          handleSaveModule={handleSaveModule}
        />
      ),
      roles: ["admin", "sponsor"],
    },
    {
      name: "Settings",
      component: (
        <ModuleSettings
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          handleSaveModule={handleSaveModule}
        />
      ),
      roles: ["admin"],
    },
    ...(selectedModule?.widgetId === "l5YDfst7wXb1pc2nzucI"
      ? [
          {
            name: "Products",
            component: <ModuleProducts selectedModule={selectedModule} />,
          },
        ]
      : []),
    ...(selectedModule?.widgetId === "PvW30tMLOdE2KqbIvJPk"
      ? [
          {
            name: "Listings",
            component: <ModuleListings selectedModule={selectedModule} />,
          },
        ]
      : []),
    ...(selectedModule?.widgetId === "PvW30tMLOdE2KqbIvJPk" &&
    selectedModule?.listingId
      ? [
          {
            name: "My List",
            component: <ModuleListingItems selectedModule={selectedModule} />,
          },
        ]
      : []),
  ];

  if (!selectedModule) return null;
  return (
    <>
      <CardWithTabs
        name={`Module «${selectedModule.title}»`}
        tabs={tabs}
        defaultTab={userRole === "sponsor" ? 1 : null}
      />
    </>
  );
}
