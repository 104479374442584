import { functionsFetch } from "../utils/functionsFetch";

export const getAllProductsDevicesShop = async ({ id: deviceId, shopId }) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-devices/${deviceId}/shop/${shopId}`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const createProductTaxi = async (deviceId, data) => {
  try {
    const res = await functionsFetch({
      method: "POST",
      url: `/taxi-devices/shop/${deviceId}/create`,
      data,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateProductInTaxi = async (deviceId, data) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-devices/shop/${deviceId}`,
      data,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const deleteProductInTaxiById = async (deviceId, productId) => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-devices/shop/${deviceId}/${productId}`,
    });
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
