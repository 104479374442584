import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modale } from "../../../../../components/Modale";
import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import {
  createListingItem,
  deleteListingItemById,
  updateListingItemById,
} from "../../../../../modules/taxiModuleListingItems";
import { fetchAllListingItems } from "../../../../../store/TaxiModuleListingItem/features";
import { toast } from "react-toastify";
import { checkIsUrl } from "../../../../../utils/util";
import ImageUpload from "../../../../../components/ImageUpload";
import { storage } from "../../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const ModuleListingItemModal = ({
  isOpen,
  setIsOpen,
  selectedListingItem,
  setSelectedListingItem,
  listingId,
  ...props
}) => {
  const [listingItem, setListingItem] = useState(
    selectedListingItem || { title: "", banner: "", url: "" }
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bannerBlob, setBannerBlob] = useState(null);

  const onSubmit = async () => {
    if (listingItem.title.length < 3)
      return toast.error("You should enter a title with at least 3 characters");

    if (!listingItem.banner && !bannerBlob) {
      return toast.error("You should add a banner");
    }

    if (!checkIsUrl(listingItem.url))
      return toast.error("You should add a valid url");

    setLoading(true);
    try {
      if (selectedListingItem) {
        console.log("ListingItem :", listingItem);
        await updateListingItemById(listingId, {
          ...listingItem,
          banner: bannerBlob
            ? await handleAddImageToStorage(bannerBlob)
            : listingItem.banner,
        });
      }
      if (!selectedListingItem) {
        console.log("ListingItem :", listingItem);
        await createListingItem(listingId, {
          ...listingItem,
          banner: bannerBlob
            ? await handleAddImageToStorage(bannerBlob)
            : listingItem.banner,
        });
      }
      setListingItem({ title: "", banner: "", url: "" });
      setIsOpen(false);
      dispatch(fetchAllListingItems(listingId));
      setBannerBlob(null);
      if (selectedListingItem) setSelectedListingItem(null);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    await deleteListingItemById(listingId, listingItem.id);
    setIsOpen(false);
    setSelectedListingItem(null);
    setListingItem({ title: "", banner: "", url: "" });
    dispatch(fetchAllListingItems(listingId));
    setBannerBlob(null);
  };

  const handleAddImageToStorage = async (bannerBlob) => {
    if (!bannerBlob) return null;
    const fileName = Date.now().toString();
    const metadata = {
      contentType: bannerBlob?.type || "image/jpeg",
    };

    const imageRef = ref(storage, "images/" + fileName);
    await uploadBytes(imageRef, bannerBlob, metadata);

    return getDownloadURL(imageRef).then((downloadURL) => {
      return downloadURL;
    });
  };

  useEffect(() => {
    if (selectedListingItem) setListingItem(selectedListingItem);
  }, [selectedListingItem]);

  return (
    <Modale
      title={selectedListingItem ? "Item settings" : "Create item"}
      close={() => {
        setSelectedListingItem(null);
        setListingItem({ title: "", banner: "", url: "" });
        setBannerBlob(null);
        setIsOpen(false);
      }}
      actions={
        selectedListingItem
          ? [
              {
                title: "Submit",
                click: onSubmit,
                disabled: loading,
              },
              {
                title: "Delete",
                click: onDelete,
                disabled: loading,
              },
            ]
          : [
              {
                title: "Submit",
                click: onSubmit,
                disabled: loading,
              },
            ]
      }
      isOpen={isOpen}
      {...props}
    >
      <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
        Title
        <Input
          type="text"
          id="title"
          placeholder="Enter a title for this item"
          value={listingItem.title}
          onChange={(e) =>
            setListingItem({ ...listingItem, title: e.target.value })
          }
          name="title"
        />
      </LabelInput>
      <ImageUpload
        title="Banner"
        initialPreview={
          bannerBlob
            ? URL.createObjectURL(bannerBlob)
            : listingItem.banner || ""
        }
        setBlob={setBannerBlob}
        ratio={3 / 1}
      />
      <LabelInput style={{ minWidth: "300px" }} htmlFor="url">
        URL
        <Input
          type="text"
          id="url"
          placeholder="Enter url for this item"
          value={listingItem.url}
          onChange={(e) =>
            setListingItem({ ...listingItem, url: e.target.value })
          }
          name="title"
        />
      </LabelInput>
    </Modale>
  );
};
