import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProgramFidelityUsers } from "../../modules/programFidelityRequests"

export const fetchAllProgramFidelity = createAsyncThunk(
    "programFidelityList/fetchAllProgramFidelity",
    async (arg, thunkAPI) => {
      try {
        const responseData = await getAllProgramFidelityUsers() ;
        return responseData;
      } catch (e) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );