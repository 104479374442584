import { functionsFetch } from "../utils/functionsFetch";

export const getAllCampaigns = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-campaigns`,
    });

    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateCampaignById = async (id, data) => {
  try {
    const response = await functionsFetch({
      method: "PUT",
      url: `taxi-campaigns/${id}`,
      data,
    });
    if (response.data.status === 404) {
      return Promise.reject({
        message: data.message,
      });
    }
  } catch (err) {
    alert(err.response.data.message);
    return Promise.reject(err.response.data);
  }
};

export const createCampaignById = async (campaign) => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-campaigns`,
      data: campaign,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    alert(error.response.data.error);
    return Promise.reject(error.response.data);
  }
};

export const deleteCampaignById = async (id) => {
  try {
    const response = await functionsFetch({
      method: "DELETE",
      url: `/taxi-campaigns/${id}`,
    });
  } catch (error) {
    alert(error.response.data.error);
    return Promise.reject(error.response.data);
  }
};
