import { functionsFetch } from "../utils/functionsFetch";

export const createModule = async (module) => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/`,
      data: module,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const duplicateModule = async (id) => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/duplicate/${id}`,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllModules = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateModuleById = async ({ id, data }) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-modules/${id}`,
      data,
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const deleteModuleById = async (id) => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-modules/${id}`,
    });
    console.log(res.data);
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};
