import { useMemo } from "react";
import { memo } from "react";
import styled, { css } from "styled-components";
import { TablerIcon } from "../../../../../components/TablerIconsSelector";

const StyledPreview = styled.div`
  background-color: whitesmoke;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;

  ${({ preview, width }) =>
    preview &&
    css`
      width: 100% !important;
      grid-column: ${width === "simple" ? "auto" : "span 2"};
    `}

  p {
    font-family: ${(props) => props.font};
    font-size: ${(props) => props.fontSize};
    line-height: ${(props) => props.fontSize};
    text-shadow: 0 4px 12px
      rgba(90, 90, 90, ${(props) => props.textShadowOpacity});
    color: ${(props) => props.textColor};
    margin: 0;
    text-align: center;
  }
  width: ${(props) => {
    if (props.width === "simple") {
      return "50%";
    }
    if (props.width === "double") {
      return "100%";
    }
  }};
  aspect-ratio: ${(props) => {
    if (props.width === "simple") {
      return "4/3";
    }
    if (props.width === "double") {
      return "8/3";
    }
  }};
  background: ${(props) => {
    if (props.type === "full") {
      return props.primary;
    }
    if (props.type === "vertical") {
      return `linear-gradient(
      180deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    if (props.type === "horizontal") {
      return `linear-gradient(
      90deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    if (props.type === "crosswise") {
      return `linear-gradient(
      135deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    if (props.type === "image") {
      return `center / cover no-repeat url(${props.backgroundImage})`;
    }
  }};
  border-radius: ${(props) => props.cornerRadius};
  border: ${(props) => props.borderSize} solid ${(props) => props.borderColor};
`;

export const ModulePreview = memo(({ selectedModule, ...props }) => {
  const url = useMemo(() => {
    return selectedModule.style?.blob
      ? URL.createObjectURL(selectedModule.style?.blob)
      : selectedModule.style?.backgroundImage;
  }, [selectedModule.style?.backgroundImage, selectedModule.style?.blob]);

  return (
    <StyledPreview
      font={selectedModule.style?.font || "Verdana"}
      fontSize={
        typeof selectedModule.style?.fontSize === "number"
          ? selectedModule.style?.fontSize + "px"
          : selectedModule.style?.fontSize || "18px"
      }
      textShadowOpacity={selectedModule.style?.textShadowOpacity || "0"}
      textColor={selectedModule.style?.textColor || "#000000"}
      primary={selectedModule.style?.backgroundColors?.primary || "whitesmoke"}
      secondary={selectedModule.style?.backgroundColors?.secondary || "#ffffff"}
      type={selectedModule.style?.backgroundType || "full"}
      width={selectedModule.style?.width || "simple"}
      cornerRadius={selectedModule.style?.cornerRadius || "12px"}
      borderSize={selectedModule.style?.borderSize + "px" || "0"}
      borderColor={selectedModule.style?.borderColor || "#000000"}
      backgroundImage={url}
      {...props}
    >
      <TablerIcon
        name={selectedModule.style?.icon || ""}
        size={selectedModule.style?.iconSize || 32}
        color={selectedModule.style?.iconColor || "#000000"}
        stroke={selectedModule.style?.iconStroke || 1}
      />
      <p>{selectedModule.style?.title ?? selectedModule.title}</p>
    </StyledPreview>
  );
});
