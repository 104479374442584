import { functionsFetch } from "../utils/functionsFetch";

export const getAllRates = async (deviceId) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-devices/rates/${deviceId}`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
