import React from "react";
import RatesTable from "../../components/RatesTable";

const DeviceRates = ({ selectedDevice }) => {
  return (
    <>
      <RatesTable selectedDevice={selectedDevice} />
    </>
  );
};

export default DeviceRates;
