import { useState, useEffect } from "react";
import { Modale } from "../../../../components/Modale";
import { MDBDataTable } from "mdbreact";
import { Button, IconButton, Tooltip } from "@mui/material";
import { ModuleGroupModal } from "./ModuleGroupModal";
import {
  deleteModuleGroupById,
  duplicateModuleGroup,
} from "../../../../modules/taxiModuleGroups";
import { useDispatch } from "react-redux";
import { fetchTaxiModuleGroups } from "../../../../store/TaxiModuleGroups/features";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileCopy } from "@mui/icons-material";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import { format } from "date-fns";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export function ModuleGroupsModal({
  moduleGroups,
  setSelectedModuleGroup,
  isOpen,
  close,
}) {
  const [dataTable, setDataTable] = useState([]);
  const [moduleGroupModalOpen, setModuleGroupModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const dispatch = useDispatch();

  const handleDeleteModuleGroup = async (id) => {
    await deleteModuleGroupById(id);
    dispatch(fetchTaxiModuleGroups());
    setConfirmDeleteModalOpen(false);
  };

  const handleDuplicateModuleGroup = async (id) => {
    await duplicateModuleGroup(id);
    dispatch(fetchTaxiModuleGroups());
    dispatch(fetchTaxiModules());
  };

  const fetchData = () => {
    const columnNames = ["Id", "Name", "Created_At", "Action"];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (moduleGroups?.length > 0) {
      moduleGroups.map((item, index) => {
        rows.push({
          id: index + 1,
          name: item.name,
          created_at: format(item.createdAt, "MM/dd/yyyy HH:mm"),
          action: (
            <Buttons>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setGroupToEdit(item);
                }}
              >
                EDIT
              </Button>
              {moduleGroups.length > 1 && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setGroupToDelete(item);
                      setConfirmDeleteModalOpen(true);
                    }}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Duplicate">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDuplicateModuleGroup(item.id);
                  }}
                >
                  <FileCopy color="primary" />
                </IconButton>
              </Tooltip>
            </Buttons>
          ),
          clickEvent: () => {
            setSelectedModuleGroup(item);
            close();
          },
        });
      });
    }
    setDataTable({ columns, rows });
  };

  useEffect(() => {
    if (moduleGroups !== null) fetchData();
  }, [moduleGroups]);

  return (
    <>
      <Modale
        title={`All module groups`}
        isOpen={isOpen}
        close={() => close()}
        actions={[
          {
            title: "Add",
            click: () => setModuleGroupModalOpen(true),
          },
        ]}
        cancelText="Cancel"
      >
        <ModuleGroupModal
          isOpen={moduleGroupModalOpen === true || groupToEdit !== null}
          close={() => {
            setModuleGroupModalOpen(false);
            setGroupToEdit(null);
          }}
          groupToEdit={groupToEdit}
          setGroupToEdit={setGroupToEdit}
          setSelectedModuleGroup={setSelectedModuleGroup}
        />
        {groupToDelete && (
          <ConfirmationModal
            title="Delete module group"
            content={`Do you realy want to delete «${groupToDelete.name}» ?'`}
            isOpen={confirmDeleteModalOpen}
            close={() => {
              setGroupToDelete(null);
              setConfirmDeleteModalOpen(false);
            }}
            onConfirmation={() => handleDeleteModuleGroup(groupToDelete.id)}
          />
        )}
        <MDBDataTable
          striped
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "on", "results"]}
          sortable
          searching
          paging={dataTable && dataTable.rows && dataTable.rows.length > 10}
          paginationLabel={[
            <div style={{ cursor: "pointer" }}>Previous</div>,
            <div style={{ cursor: "pointer" }}>Next</div>,
          ]}
          searchLabel={"Search"}
          noRecordsFoundLabel={"Nothing found"}
          entriesLabel={"Show results"}
          data={dataTable}
        />
      </Modale>
    </>
  );
}
