import { useState, useEffect } from "react";
import { Modale } from "../../../../components/Modale";
import {
  createModuleGroup,
  updateModuleGroupById,
} from "../../../../modules/taxiModuleGroups";
import { useDispatch } from "react-redux";
import { fetchTaxiModuleGroups } from "../../../../store/TaxiModuleGroups/features";
import {
  Input,
  LabelInput,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";

export function ModuleGroupModal({
  isOpen,
  close,
  groupToEdit,
  setGroupToEdit,
  setSelectedModuleGroup,
}) {
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  const handleSaveGroup = async () => {
    if (name !== "" && !groupToEdit) {
      await createModuleGroup(name);
      dispatch(fetchTaxiModuleGroups());
      setName("");
      close();
    }
    if (name !== "" && groupToEdit) {
      await updateModuleGroupById({
        id: groupToEdit.id,
        name,
      });
      setSelectedModuleGroup((group) => ({
        ...group,
        name,
      }));
      dispatch(fetchTaxiModuleGroups());
      setGroupToEdit(null);
      setName("");
      close();
    }
  };

  useEffect(() => {
    setName(groupToEdit?.name || "");
  }, [groupToEdit]);

  return (
    <>
      <Modale
        title={(groupToEdit ? "Edit" : "Create") + " module group"}
        isOpen={isOpen}
        close={() => close()}
        actions={[
          {
            title: "Save",
            click: () => handleSaveGroup(),
          },
        ]}
        cancelText="Cancel"
      >
        <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
          Name
          <Input
            type="text"
            id="name"
            placeholder="Enter a name for this group"
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
          />
        </LabelInput>
      </Modale>
    </>
  );
}
