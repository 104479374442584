import { functionsFetch } from "../utils/functionsFetch";

export const getAllBusinessCards = async (cardId) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `users/business-cards/all`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
