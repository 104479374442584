import React, { useState } from "react";
import { AddOutlined } from "@mui/icons-material";
import ModalAddResources from "./ModalAddResources";

const ButtonAddResource = ({ uid }) => {
  const [isModalResourceOpen, setIsModalResourceOpen] = useState(false);
  return (
    <>
      <button onClick={() => setIsModalResourceOpen(true)}>
        <AddOutlined />
        Ajouter des resources
      </button>
      {isModalResourceOpen && (
        <ModalAddResources
          uid={uid}
          isOpen={isModalResourceOpen}
          close={() => setIsModalResourceOpen(false)}
        />
      )}
    </>
  );
};

export default ButtonAddResource;
