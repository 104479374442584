import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ratesSelector } from "../../../../store/TaxiModuleRates";
import { fetchTaxiModuleRates } from "../../../../store/TaxiModuleRates/features";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import CardContent from "@mui/material/CardContent";
import { MDBDataTable } from "mdbreact";
import ModalRate from "./ModalRate";

const RatesTable = ({ selectedDevice }) => {
  const dispatch = useDispatch();
  const { rates, isLoading } = useSelector(ratesSelector);
  const [dataTable, setDataTable] = useState([]);
  const [selectedRateDetails, setSelectedRateDetails] = useState(null);

  const fetchData = useCallback(() => {
    const columnNames = ["Index", "Note", "Date", "Action"];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (rates.length > 0) {
      rates.map((item, id) => {
        return rows.push({
          index: id + 1,
          note: item.average,
          date: item.date ? format(item.date, "yyyy/MM/dd") : "Invalid date",
          action: (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRateDetails(item);
              }}
              variant={"contained"}
            >
              Action
            </Button>
          ),
        });
      });
    }
    setDataTable({ columns, rows });
  }, [rates]);

  useEffect(() => {
    dispatch(fetchTaxiModuleRates(selectedDevice?.id));
  }, [dispatch, selectedDevice?.id]);

  useEffect(() => {
    if (!isLoading) fetchData();
  }, [isLoading, fetchData]);

  return (
    <>
      <CardContent>
        <MDBDataTable
          striped
          entriesOptions={[10, 20, 50]}
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "of", "results"]}
          sortable
          searchLabel={"Search"}
          noRecordsFoundLabel={"Nothing found here"}
          paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
          paginationLabel={[
            <div style={{ cursor: "pointer" }}>Previous</div>,
            <div style={{ cursor: "pointer" }}>Next</div>,
          ]}
          info={true}
          entriesLabel={"Show results"}
          data={dataTable}
          sortRows={["usedAt"]}
        />
      </CardContent>
      <ModalRate
        selectedRate={selectedRateDetails}
        setSelectedRate={setSelectedRateDetails}
      />
    </>
  );
};
export default RatesTable;
