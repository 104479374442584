import { functionsFetch } from "../utils/functionsFetch";

export const createListingItem = async (listingId, item) => {
  try {
    const res = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/listings/items/${listingId}`,
      data: item,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllListingItems = async (listingId) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/listings/items/${listingId}`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateListingItemById = async (listingId, item) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-modules/listings/items/${listingId}/${item.id}`,
      data: item,
    });
    console.log(res.data);
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const deleteListingItemById = async (listingId, itemId) => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-modules/listings/items/${listingId}/${itemId}`,
    });
    console.log(res.data);
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};
