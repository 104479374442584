import { createSlice } from "@reduxjs/toolkit";
import { fetchAllAnalyticsCampaigns } from "./features";

export const analyticsCampaigns = createSlice({
  name: "analyticsCampaigns",
  initialState: {
    analyticsCampaigns: [],
    isLoading: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAnalyticsCampaigns.fulfilled, (state, { payload }) => {
        state.analyticsCampaigns = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllAnalyticsCampaigns.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllAnalyticsCampaigns.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default analyticsCampaigns.reducer;

export const analyticsCampaignsSelector = (state) => state.analyticsCampaigns;
