import { createAsyncThunk } from "@reduxjs/toolkit";
import { functionsFetch } from "../../../utils/functionsFetch";

export const fetchUsersCount = createAsyncThunk(
  "Count/fetchUsersCount",
  async (arg, thunkAPI) => {
    try {
      const response = await functionsFetch({
        method: "GET",
        url: "/users/all/count",
      });
      return response.data;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const fetchUsersCountByWhitelabel = createAsyncThunk(
  "Count/fetchUsersCountByWhitelabel",
  async ({ whitelabel }, thunkAPI) => {
    try {
      const response = await functionsFetch({
        method: "GET",
        url: `/users/all/count/${whitelabel}`,
      });
      return response.data;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
