import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ModalSaleOnBoard } from "./ModalSaleOnBoard";
import { useSelector, useDispatch } from "react-redux";
import { fetchTaxiModulesShop } from "../../../../store/TaxiModulesShop/features";
import { fetchTaxideviceShop } from "../../../../store/TaxiDevicesShop/features";
import { updateProductInTaxi } from "../../../../modules/taxiDevicesShop";
import { DragAndDropTable } from "../../../../components/DragAndDropTable";
import { toast } from "react-toastify";

const ContainerBtn = styled.div`
  width: 100%;
  .btn-add-product {
    display: flex;
    justify-content: end;
    margin: 12px 0;
  }
`;
const ImageProduct = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableSaleOnBoard = ({ selectedDevice }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const nextUpdtateTime = useRef(Date.now());
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { allProductsDevices, loading } = useSelector(
    (state) => state.taxiDevicesShop
  );

  //This will trigger handleUpdateModuleRanks function 2 seconds later from now
  const setupNextUpdate = (reorderedItems) => {
    nextUpdtateTime.current = Date.now() + 1990;
    setTimeout(async () => {
      await handleUpdateProductsRanks(reorderedItems);
    }, "2000");
  };

  //This function will loop through items and save their ranks in modules database
  const handleUpdateProductsRanks = async (reorderedItems) => {
    if (nextUpdtateTime.current <= Date.now()) {
      for (let i = 0; i < reorderedItems.length; i++) {
        const deviceId = reorderedItems[i].deviceId;
        await updateProductInTaxi(deviceId, {
          id: reorderedItems[i].id,
          stock: reorderedItems[i].stock,
          stockMinimal: reorderedItems[i].stockMinimal,
          rank: i + 1,
        });
      }
      dispatch(fetchTaxideviceShop(selectedDevice));
      toast.success("Succesfuly updated products order.");
    }
  };

  //This is used to sort items in the table based on their rank
  const setOrderedItems = (rawProducts) => {
    const orderedItems = [...rawProducts].sort(function (a, b) {
      return a.rank - b.rank;
    });
    setItems(orderedItems);
  };

  useEffect(() => {
    if (selectedDevice) {
      dispatch(fetchTaxiModulesShop(selectedDevice?.shopId));
    }
    dispatch(fetchTaxideviceShop(selectedDevice));
  }, [dispatch]);

  useEffect(() => {
    if (!loading) setOrderedItems(allProductsDevices);
    return () => {
      setItems([]);
    };
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        const tableCard = document.getElementById("table");
        const rows = tableCard.querySelectorAll("tr");

        rows.forEach((row) => {
          const stock = row.children[2].textContent;
          const stockMinimal = row.children[3].textContent;

          if (Number(stock) <= Number(stockMinimal)) {
            row.style.backgroundColor = "#FC7878";
          } else {
            row.style.backgroundColor = "#FFF";
          }
        });
      }, 5);

      return () => clearTimeout(timer);
    }
  }, [items, loading]);
  return (
    <>
      <ContainerBtn>
        <div className="btn-add-product">
          <Button onClick={() => setSelectedProduct(true)}>
            ADD A PRODUCT
          </Button>
        </div>
      </ContainerBtn>
      <DragAndDropTable
        cols={["Image", "Name", "Stock", "Stock_minimal", "Price", "Action"]}
        items={items.map((item) => {
          return {
            ...item,
            Image: item.image && (
              <ImageProduct>
                <img
                  width={50}
                  height={50}
                  src={item.image}
                  alt="products for sale"
                />
              </ImageProduct>
            ),
            Name: item.name,
            Stock: item.stock,
            Stock_minimal: item.stockMinimal,
            Price: item.price + " €",
            Action: (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedProduct(item);
                }}
                variant={"contained"}
              >
                Action
              </Button>
            ),
          };
        })}
        handleItemsMoved={(reorderedItems) => {
          setItems(reorderedItems);
          setupNextUpdate(reorderedItems);
        }}
      />
      <ModalSaleOnBoard
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
        selectedDevice={selectedDevice}
        creation={!Boolean(selectedProduct?.id)}
      />
    </>
  );
};
