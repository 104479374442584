import { createSlice } from "@reduxjs/toolkit";
import { fetchAllListings } from "./features";

export const slice = createSlice({
  name: "moduleListing",
  initialState: {
    listings: [],
    isLoading: false,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllListings.fulfilled, (state, { payload }) => {
        state.listings = payload.data;
      })
      .addCase(fetchAllListings.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllListings.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default slice.reducer;
