import React, { createRef } from "react";
import "leaflet/dist/leaflet.css";
import LMap from "./MapConfig";
import iconUrl from "./ToolTip.svg";
import L from "leaflet";

const Map = ({ locations, mapInitial, locationsAdmin, eventHandlers }) => {
  const getIcon = (_iconSize) => {
    return L.icon({
      iconUrl: iconUrl,
      iconAnchor: [25, 40],
      popupAnchor: [0, -50],
      iconSize: new L.Point(50, 45),
    });
  };
  return (
    <LMap
      key={JSON.stringify([mapInitial.lat, mapInitial.lng])}
      center={[mapInitial.lat, mapInitial.lng]}
      zoom={mapInitial.zoom}
      style={{ width: "100%", height: "100%" }}
    >
      {({ TileLayer, Marker, Circle }) => (
        <>
          <TileLayer
            attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {locationsAdmin.map((locationAdmin) => {
            const location = locations.find(
              ({ label }) => locationAdmin === label
            );
            const position = location
              ? [location.pos.lat, location.pos.lng]
              : [0, 0];
            const markerRef = createRef();
            return (
              <>
                <Marker
                  icon={getIcon()}
                  draggable
                  ref={markerRef}
                  eventHandlers={eventHandlers(location?.label, markerRef)}
                  position={position}
                  key={location?.label}
                />
                {location?.range && (
                  <Circle
                    color="#000"
                    fillColor="#000"
                    center={position}
                    radius={location.range}
                  />
                )}
              </>
            );
          })}
        </>
      )}
    </LMap>
  );
};
export default Map;
