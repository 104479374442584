import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { DeviceEdit } from "./components/DeviceEdit";
import { CardWithTabs } from "../../../../components/CardWithTabs";
import DeviceSettings from "./components/DeviceSettings";
import { updateDeviceById } from "../../../../modules/taxiDevices";
import { fetchTaxiDevices } from "../../../../store/TaxiDevices/features";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import { TableSaleOnBoard } from "../components/TableSaleOnBoard";
import { toast } from "react-toastify";
import DeviceRates from "./components/DeviceRates";

export default function Device() {
  const { id } = useParams();
  const devices = useSelector((state) => state.taxiDevices.value);
  const [selectedDevice, setSelectedDevice] = useState(
    devices.find((device) => device.id === id) || null
  );
  const userRole = JSON.parse(sessionStorage.getItem("user")).role;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSaveDevice = async () => {
    if (checkPassword()) {
      await updateDeviceById({ id, data: selectedDevice });
      dispatch(fetchTaxiDevices());
      navigate("/dashboard/taxis");
    }
  };

  const checkPassword = () => {
    const check = /^[1-9]+$/.test(selectedDevice.adminPassword);
    if (!check) {
      toast.error("Your paswword can only have numbers from 1 to 9.");
    }
    return check;
  };

  const tabs = [
    ...(userRole === "admin"
      ? [
          {
            name: "Edit",
            component: (
              <DeviceEdit
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                handleSaveDevice={handleSaveDevice}
              />
            ),
          },
          {
            name: "Settings",
            component: (
              <DeviceSettings
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                handleSaveDevice={handleSaveDevice}
              />
            ),
          },
          {
            name: "Rates",
            component: <DeviceRates selectedDevice={selectedDevice} />,
          },
        ]
      : []),

    ...(selectedDevice?.shopId
      ? [
          {
            name: "Vente à bord",
            component: <TableSaleOnBoard selectedDevice={selectedDevice} />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    dispatch(fetchTaxiModules());
  }, []);

  if (!selectedDevice) return null;
  return <CardWithTabs name={`Device «${selectedDevice.name}»`} tabs={tabs} />;
}
