import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiDevices } from "./features";

export const taxiDevices = createSlice({
    name: "taxiDevices",
    initialState: {
        value: [],
        loading: true,
        error: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTaxiDevices.fulfilled, (state, { payload }) => {
                state.value = payload;
                state.loading = false;
                state.error = false;
            })
            .addCase(fetchTaxiDevices.pending, (state, { payload }) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchTaxiDevices.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export default taxiDevices.reducer;

export const taxiDevicesSelector = (state) => state.taxiDevices;
