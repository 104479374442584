import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import { fetchTaxiWidgets } from "../../../../store/TaxiWidgets/features";
import { Status } from "../../../../components/Status";
import { Button, CardContent, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import {
  duplicateModule,
  updateModuleById,
} from "../../../../modules/taxiModules";
import { toast } from "react-toastify";
import { fetchTaxiModuleGroups } from "../../../../store/TaxiModuleGroups/features";
import { ModuleGroupsModal } from "./ModuleGroupsModal";
import { FileCopy } from "@mui/icons-material";
import { DragAndDropTable } from "../../../../components/DragAndDropTable";

const GroupSelection = styled.div`
  display: flex;
  gap: 18px;
  p {
    margin: 0;
  }
  align-items: center;
`;

const getStatus = (status) => {
  if (status === false) return "refused";
  if (status === true) return "accepted";
  return status;
};

export const ModulesTable = ({
  moduleGroups,
  selectedModuleGroup,
  setSelectedModuleGroup,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modules = useSelector((state) => state.taxiModules.value);
  const widgets = useSelector((state) => state.taxiWidgets.value);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const nextUpdtateTime = useRef(Date.now());
  const [moduleGroupModalOpen, setModuleGroupModalOpen] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));

  //This will trigger handleUpdateModuleRanks function 2 seconds later from now
  const setupNextUpdate = (reorderedItems) => {
    nextUpdtateTime.current = Date.now() + 1990;
    setTimeout(async () => {
      await handleUpdateModulesRanks(reorderedItems);
    }, "2000");
  };

  //This function will loop through items and save their ranks in modules database
  const handleUpdateModulesRanks = async (reorderedItems) => {
    if (nextUpdtateTime.current <= Date.now()) {
      for (let i = 0; i < reorderedItems.length; i++) {
        const { id } = reorderedItems[i];
        const data = { rank: i + 1 };
        await updateModuleById({ id, data });
      }
      dispatch(fetchTaxiModules());
      toast.success("Succesfuly updated modules order.");
    }
  };

  //This is used to sort items in the table based on their rank
  const setOrderedItems = (rawModules) => {
    const orderedItems = [...rawModules].sort(function (a, b) {
      return a.rank - b.rank;
    });
    if (user.role !== "sponsor") {
      return setItems(
        orderedItems.filter((item) => item.groupId === selectedModuleGroup?.id)
      );
    }
    setItems(orderedItems);
  };

  const buildModuleTypeString = (module) => {
    if (module.type === "widget") {
      const widget = widgets.find((widget) => {
        return widget.id === module.widgetId;
      });
      return `Widget (${widget?.name})`;
    }
    if (module.type === "qrcode") {
      return "Url";
    }
  };

  const handleDuplicateModuleClicked = async (id) => {
    setLoading(true);
    const response = await duplicateModule(id);
    if (response) {
      dispatch(fetchTaxiModules());
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(fetchTaxiWidgets());
    dispatch(fetchTaxiModules());
    dispatch(fetchTaxiModuleGroups());
  }, []);

  useEffect(() => {
    const filteredModules = modules.filter((module) => {
      if (user.role === "vendor") return module.accountUid === user.user.uid;
      if (user.role === "sponsor") return module.accountUid === user.user.uid;
      return module;
    });
    setOrderedItems(filteredModules);
  }, [modules, user.role, user.user.uid, selectedModuleGroup]);

  useEffect(() => {
    const moduleGroup = moduleGroups.find(
      (group) => selectedModuleGroup?.id === group.id
    );
    if (!moduleGroup) setSelectedModuleGroup(moduleGroups[0]);
  }, [moduleGroups]);

  return (
    <>
      <CardContent>
        {user.role !== "sponsor" && (
          <GroupSelection>
            <p>
              <strong>Module Group : </strong>«{" "}
              {selectedModuleGroup?.name || "No  infos"} »
            </p>
            <Tooltip
              title="All groups"
              onClick={() => {
                setModuleGroupModalOpen(true);
              }}
            >
              <IconButton style={{ outline: "none" }}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </GroupSelection>
        )}
        <DragAndDropTable
          cols={["index", "title", "Type", "status", "action"]}
          items={items.map((item, index) => {
            return {
              ...item,
              index,
              title: item.title,
              Type: buildModuleTypeString(item),
              status: (
                <Status status={getStatus(item.active)}>
                  {item.active ? "Active" : "Inactive"}
                </Status>
              ),
              action: (
                <div className="actions-container">
                  <Button
                    onClick={() =>
                      navigate(`/dashboard/taxis/module/${item.id}`)
                    }
                    variant={"contained"}
                    disabled={loading}
                  >
                    EDIT
                  </Button>
                  <Tooltip title="Duplicate">
                    <IconButton
                      onClick={() => handleDuplicateModuleClicked(item.id)}
                      disabled={loading}
                      hidden={user.role !== "admin"}
                    >
                      <FileCopy color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              ),
            };
          })}
          handleItemsMoved={(reorderedItems) => {
            setItems(reorderedItems);
            setupNextUpdate(reorderedItems);
          }}
        />
      </CardContent>
      <ModuleGroupsModal
        isOpen={moduleGroupModalOpen}
        close={() => setModuleGroupModalOpen(false)}
        moduleGroups={moduleGroups}
        setSelectedModuleGroup={setSelectedModuleGroup}
      />
    </>
  );
};
