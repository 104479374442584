import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiWidgets } from "./features";

export const taxiWidgets = createSlice({
  name: "taxiWidgets",
  initialState: {
    value: [],
    loading: true,
    error: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTaxiWidgets.fulfilled, (state, { payload }) => {
        state.value = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchTaxiWidgets.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchTaxiWidgets.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default taxiWidgets.reducer;

export const taxiWidgetsSelector = state => state.taxiWidgets;
