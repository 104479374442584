import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiModuleGroups } from "./features";

export const taxiModuleGroups = createSlice({
  name: "taxiModuleGroups",
  initialState: {
    value: [],
    loading: true,
    error: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxiModuleGroups.fulfilled, (state, { payload }) => {
        state.value = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchTaxiModuleGroups.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchTaxiModuleGroups.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default taxiModuleGroups.reducer;

export const taxiModuleGroupsSelector = (state) => state.taxiModuleGroups;
