import { configureStore } from "@reduxjs/toolkit";
import whitelabelListReducer from "./Whitelabel/WhitelabelList";
import allEarningsReducer from "./Whitelabel/Earnings";
import usersListReducer from "./Users/UsersList";
import usersCountReducer from "./Users/Count";
import requestsReducer from "./Requests";
import ordersListReducer from "./Orders/OrderList";
import orderProcessedReducer from "./Orders/OrderProcessed";
import linksReducer from "./Links";
import programFidelityListReducer from "./ProgramFidelityList";
import taxiGroupsReducer from "./TaxiGroups";
import taxiDevicesReducer from "./TaxiDevices";
import TaxiDevicesShopReducer from "./TaxiDevicesShop";
import taxiModulesReducer from "./TaxiModules";
import taxiModuleGroupsReducer from "./TaxiModuleGroups";
import taxiModulesShopReducer from "./TaxiModulesShop";
import taxiWidgetsReducer from "./TaxiWidgets";
import campaignsReducer from "./Campaigns";
import giftsReducer from "./Gifts";
import moduleListingReducer from "./TaxiModuleListing";
import moduleListingItemReducer from "./TaxiModuleListingItem";
import ratesReducer from "./TaxiModuleRates";
import analyticsCampaignsReducer from "./AnalyticsCampaigns";
import reportsReducer from "./reports";
import businessCardTemplatesReducer from "./BusinessCardTemplates";

export default configureStore({
  reducer: {
    whitelabelList: whitelabelListReducer,
    usersList: usersListReducer,
    usersCount: usersCountReducer,
    ordersList: ordersListReducer,
    orderProcessed: orderProcessedReducer,
    requests: requestsReducer,
    links: linksReducer,
    allEarnings: allEarningsReducer,
    programFidelityList: programFidelityListReducer,
    taxiGroups: taxiGroupsReducer,
    taxiDevices: taxiDevicesReducer,
    taxiModules: taxiModulesReducer,
    taxiModuleGroups: taxiModuleGroupsReducer,
    taxiWidgets: taxiWidgetsReducer,
    taxiModulesShop: taxiModulesShopReducer,
    taxiDevicesShop: TaxiDevicesShopReducer,
    campaigns: campaignsReducer,
    rates: ratesReducer,
    gifts: giftsReducer,
    businessCardTemplates: businessCardTemplatesReducer,
    moduleListing: moduleListingReducer,
    moduleListingItem: moduleListingItemReducer,
    analyticsCampaigns: analyticsCampaignsReducer,
    reports: reportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
