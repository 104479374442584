import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllWidgets } from "../../modules/taxiWidgets";

export const fetchTaxiWidgets = createAsyncThunk(
  "taxiWidgets/fetchWidgets",
  async (arg, thunkAPI) => {
    try {
      const response = await getAllWidgets();
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
