import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CheckBox } from "../../../../../components/CheckBox";
import { format } from "date-fns";
import {
  Select,
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { useEffect, useState } from "react";
import { fetchTaxiModuleGroups } from "../../../../../store/TaxiModuleGroups/features";
import { doc, query, onSnapshot, getFirestore } from "firebase/firestore";
import { LastOnline } from "../../../../../components/LastOnline";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: no-wrap;
  font-weight: bold;
`;

const Fields = styled.div`
  width: 50%;
`;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

const shops = [
  {
    title: "Shop",
    id: "l5YDfst7wXb1pc2nzucI",
  },
];

export function DeviceEdit({
  selectedDevice,
  setSelectedDevice,
  handleSaveDevice,
}) {
  const groups = useSelector((state) => state.taxiGroups.taxiGroups);
  const moduleGroups = useSelector((state) => state.taxiModuleGroups.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const db = getFirestore();

  useEffect(() => {
    dispatch(fetchTaxiModuleGroups());
    if (db) {
      const q = query(doc(db, "taxi_devices", selectedDevice.id));
      onSnapshot(q, (querySnapshot) => {
        setSelectedDevice({
          ...selectedDevice,
          lastOnline: querySnapshot.data().lastOnline,
        });
      });
    }
  }, [db]);

  return (
    <section>
      <Container>
        <Fields>
          <LabelInput htmlFor="name">
            Name
            <Input
              type="text"
              id="name"
              placeholder="Name this device"
              value={selectedDevice.name}
              onChange={(e) =>
                setSelectedDevice({ ...selectedDevice, name: e.target.value })
              }
              name="name"
            />
          </LabelInput>
          <LabelInput htmlFor="groupId">
            Group
            <Select
              onChange={(e) =>
                setSelectedDevice({
                  ...selectedDevice,
                  groupId: e.target.value,
                })
              }
              value={selectedDevice.groupId}
              name="groupId"
            >
              {groups?.map((group) => {
                return (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                );
              })}
            </Select>
          </LabelInput>
          <LabelInput htmlFor="shopId">
            Shop
            <Select
              onChange={(e) =>
                setSelectedDevice({
                  ...selectedDevice,
                  shopId: e.target.value,
                })
              }
              value={selectedDevice.shopId}
              name="shopId"
            >
              <option selected value="">
                Disabled
              </option>
              {shops.map((shop) => {
                return (
                  <option key={shop.id} value={shop.id}>
                    {shop.title}
                  </option>
                );
              })}
            </Select>
          </LabelInput>
          <LabelInput htmlFor="moduleGroup">
            Module Group
            <Select
              onChange={(e) =>
                setSelectedDevice({
                  ...selectedDevice,
                  moduleGroup: e.target.value,
                })
              }
              value={selectedDevice.moduleGroup}
              name="moduleGroup"
            >
              {moduleGroups?.map((moduleGroup) => {
                return (
                  <option key={moduleGroup.id} value={moduleGroup.id}>
                    {moduleGroup.name}
                  </option>
                );
              })}
            </Select>
          </LabelInput>
        </Fields>
        <Fields>
          <LabelInput htmlFor="token">
            Token
            <Input
              type="text"
              id="token"
              value={selectedDevice.token}
              disabled={true}
              name="token"
            />
          </LabelInput>
          <LabelInput htmlFor="layout">
            Layout
            <Select
              onChange={(e) =>
                setSelectedDevice({
                  ...selectedDevice,
                  layout: e.target.value,
                })
              }
              value={selectedDevice.layout || "SQUARE"}
              name="layout"
            >
              <option value="SQUARE">Square</option>
              <option value="SPLIT">Split</option>
            </Select>
          </LabelInput>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Last online</p>
            <LastOnline
              lastOnline={selectedDevice.lastOnline}
              id="lastOnline"
              name="lastOnline"
            />
          </div>
          <CheckBox
            title="Active"
            checked={selectedDevice.active}
            onChange={(e) =>
              setSelectedDevice({
                ...selectedDevice,
                active: e.target.checked,
              })
            }
          />
        </Fields>
      </Container>
      <Buttons>
        <Button onClick={() => navigate("/dashboard/taxis")}> Back </Button>
        <Button onClick={() => handleSaveDevice()}> Save </Button>
      </Buttons>
    </section>
  );
}
