import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { MDBDataTable } from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { functionsFetch } from "../../../utils/functionsFetch";
import { orderProcessedSelector } from "../../../store/Orders/OrderProcessed";
import { fetchOrdersProcessed } from "../../../store/Orders/OrderProcessed/features";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";

export default function OrderDetails({ selectedOrder }) {
  const { orderProcessedList } = useSelector(orderProcessedSelector);
  const [order, setOrder] = React.useState(null);
  const [dataTable, setDataTable] = useState([]);
  const dispatch = useDispatch();
  const handleCompleted = async (id) => {
    if (orderProcessedList.map((x) => x.orderId).includes(id)) {
      functionsFetch({
        method: "DELETE",
        url: `ordersProcessed/${id}`,
      }).then(() => {
        dispatch(fetchOrdersProcessed());
      });
    } else {
      functionsFetch({
        method: "POST",
        url: `ordersProcessed/${id}`,
      }).then(() => {
        dispatch(fetchOrdersProcessed());
      });
    }
  };
  useEffect(() => {
    setOrder(selectedOrder.address);
    let columns = [
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>
            ID Product
          </div>
        ),
        field: "id",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Amount</div>
        ),
        field: "amount",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Text</div>
        ),
        field: "text",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Link</div>
        ),
        field: "link",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Photo</div>
        ),
        field: "photo",
      },
    ];

    let rows = [];
    if (selectedOrder.items.length > 0) {
      selectedOrder.items.map((item) => {
        rows.push({
          id: item.productId,
          status: <Chip label={item.status} />,
          amount: (parseFloat(item.price) * item.qty).toFixed(2) + " €",
          text: item.text,
          link: item.link ?? "",
          photo: <img
            alt="product"
            style={item.category.includes("sticker") && { borderRadius: "50%" }}
            src={item.productImage} width={"auto"} height={50} />,
        });
      });
    }

    setDataTable({ columns, rows });
  }, [selectedOrder]);

  if (!order) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={orderProcessedList
                  .map((x) => x.orderId)
                  .includes(selectedOrder.orderId)}
                onChange={(e) => handleCompleted(selectedOrder.orderId)}
              />
            }
            label="Command completed"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <Grid item xs>
          <Typography>
            <u> Billing address</u>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Full name :</b> {order.fname}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Address : </b>
            {order.address}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>City : </b> {order.city}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Postal code :</b> {order.postal}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Country : </b> {order.country}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Complement :</b>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Company :</b> {order.company}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Email :</b> {order.email}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Phone :</b> {order.phone}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid item xs>
          <Typography>
            <u> Shipping address</u>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Full name :</b> {order.fname_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Address :</b> {order.address_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>City :</b> {order.city_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Postal code :</b> {order.postal_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Country :</b> {order.country_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Complement :</b>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Company :</b> {order.company_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Email :</b> {order.email_ship}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography>
            <b>Phone :</b> {order.phone_ship}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <MDBDataTable
          striped
          entriesOptions={[10, 20, 50]}
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "sur", "résultats"]}
          sortable
          searching={false}
          paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
          paginationLabel={[
            <div style={{ cursor: "pointer" }}>Précédent</div>,
            <div style={{ cursor: "pointer" }}>Suivant</div>,
          ]}
          searchLabel={"Recherche"}
          noRecordsFoundLabel={"aucun enregistrement correspondant trouvé"}
          info={true}
          entriesLabel={"Afficher les résultats"}
          data={dataTable}
        />
      </Grid>
    </Grid>
  );
}
