import { functionsFetch } from "../utils/functionsFetch";

export const getAllProductsModulesShop = async (widgetId) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/shop/${widgetId}`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
export const createProductsModulesShop = async (widgetId, data) => {
  try {
    const res = await functionsFetch({
      method: "post",
      url: `/taxi-modules/shop/${widgetId}/create`,
      data,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
export const updateProductsModulesShop = async (widgetId, data) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-modules/shop/${widgetId}/product`,
      data,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const deleteProductById = async (widgetId, productId) => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-modules/shop/${widgetId}/${productId}`,
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};
