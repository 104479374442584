import {
  LabelInput,
  Input,
  Select,
  BtnSuprim,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";

export const EditTabsSaleOnBoard = ({
  values,
  setValues,
  handleChange,
  creation,
  onOpenModaleDelete,
}) => {
  const allProducts = useSelector((state) => state.taxiModulesShop.allProducts);
  return (
    <>
      {creation ? (
        <LabelInput htmlFor="productId">
          TOUS LES PRODUITS
          <Select
            onChange={(e) =>
              setValues({
                ...values,
                productId: e.target.value,
              })
            }
            value={values.productId}
            name="productId"
          >
            <option value="">Select a product</option>
            {allProducts?.map((product) => {
              if (!product.status) return null;
              return (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              );
            })}
          </Select>
        </LabelInput>
      ) : (
        <LabelInput htmlFor="name">
          NAME
          <Input
            type="text"
            id="name"
            placeholder="name"
            value={values.name}
            onChange={(e) => handleChange(e.target)}
            name="name"
            disabled={!creation}
          />
        </LabelInput>
      )}
      <LabelInput style={{ minWidth: "300px" }} htmlFor="stock">
        STOCK
        <Input
          type="number"
          id="stock"
          placeholder="stock"
          value={values.stock}
          onChange={(e) => handleChange(e.target)}
          name="stock"
          disabled={!creation}
        />
      </LabelInput>
      <LabelInput htmlFor="stockMinimal">
        STOCK MINIMAL
        <Input
          type="number"
          id="stockMinimal"
          placeholder="stock minimal"
          value={values.stockMinimal}
          onChange={(e) => handleChange(e.target)}
          name="stockMinimal"
        />
      </LabelInput>
      {!creation && (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <BtnSuprim className="suprim-whitelabel" onClick={onOpenModaleDelete}>
            <DeleteOutlinedIcon />
            Delete the product
          </BtnSuprim>
        </div>
      )}
    </>
  );
};
