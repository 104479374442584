import styled from "styled-components";
import { Picker } from "../../../../../components/ModalWhiteLabel/components/Picker";
import {
  ContainerInputColor,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import ImageUpload from "../../../../../components/ImageUpload";
import { Photo } from "@mui/icons-material";

const StyledBackgroundModule = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  > label,
  > div {
    width: calc(50% - 16px);
  }
  user-select: none;
  .wrapper-collor-btn {
    justify-content: flex-start;
    gap: 8px;
  }
  .wrapper-upload-logo {
    width: max-content;
    * {
      font-size: 14px;
    }
  }
`;

const StyledBackgroundMode = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 0;
  div {
    cursor: pointer;
  }
`;

const GradientPreview = styled.div`
  width: 19%;
  aspect-ratio: 1;
  border-radius: 12px;
  background: ${(props) => props.primary};
  background: ${(props) => {
    if (props.type === "full") {
      return props.primary;
    }
    if (props.type === "vertical") {
      return `linear-gradient(
      180deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    if (props.type === "horizontal") {
      return `linear-gradient(
      90deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    if (props.type === "crosswise") {
      return `linear-gradient(
      135deg,
      ${props.primary} 0%,
      ${props.secondary} 100%
    );`;
    }
    return props.primary;
  }};
  border: 2px solid
    ${(props) => (props.type === props.currentType ? "#000000" : "#ffffff")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

function BackgroundMode({ selectedModule, setSelectedModule }) {
  return (
    <StyledBackgroundMode>
      <GradientPreview
        primary={
          selectedModule.style?.backgroundColors?.primary || "whitesmoke"
        }
        secondary={
          selectedModule.style?.backgroundColors?.secondary || "#FFFFFF"
        }
        type={"full"}
        onClick={() =>
          setSelectedModule({
            ...selectedModule,
            style: {
              ...selectedModule.style,
              backgroundType: "full",
            },
          })
        }
        currentType={selectedModule.style?.backgroundType || "full"}
      />
      <GradientPreview
        primary={
          selectedModule.style?.backgroundColors?.primary || "whitesmoke"
        }
        secondary={
          selectedModule.style?.backgroundColors?.secondary || "#FFFFFF"
        }
        type={"vertical"}
        onClick={() =>
          setSelectedModule({
            ...selectedModule,
            style: {
              ...selectedModule.style,
              backgroundType: "vertical",
            },
          })
        }
        currentType={selectedModule.style?.backgroundType || "full"}
      />
      <GradientPreview
        primary={
          selectedModule.style?.backgroundColors?.primary || "whitesmoke"
        }
        secondary={
          selectedModule.style?.backgroundColors?.secondary || "#FFFFFF"
        }
        type={"horizontal"}
        onClick={() =>
          setSelectedModule({
            ...selectedModule,
            style: {
              ...selectedModule.style,
              backgroundType: "horizontal",
            },
          })
        }
        currentType={selectedModule.style?.backgroundType || "full"}
      />
      <GradientPreview
        primary={
          selectedModule.style?.backgroundColors?.primary || "whitesmoke"
        }
        secondary={
          selectedModule.style?.backgroundColors?.secondary || "#FFFFFF"
        }
        type={"crosswise"}
        onClick={() =>
          setSelectedModule({
            ...selectedModule,
            style: {
              ...selectedModule.style,
              backgroundType: "crosswise",
            },
          })
        }
        currentType={selectedModule.style?.backgroundType || "full"}
      />
      <GradientPreview
        primary="whitesmoke"
        secondary="whitesmoke"
        type={"image"}
        onClick={() =>
          setSelectedModule({
            ...selectedModule,
            style: {
              ...selectedModule.style,
              backgroundType: "image",
            },
          })
        }
        currentType={selectedModule.style?.backgroundType || "full"}
      >
        <Photo />
      </GradientPreview>
    </StyledBackgroundMode>
  );
}

export function ModuleBackground({ selectedModule, setSelectedModule }) {
  const handleChangeColor = (color, key) => {
    let backgroundColors = { ...selectedModule.style?.backgroundColors } || {
      primary: "whitesmoke",
      secondary: "#ffffff",
    };
    setSelectedModule({
      ...selectedModule,
      style: {
        ...selectedModule.style,
        backgroundColors: { ...backgroundColors, [key]: color },
      },
    });
  };

  return (
    <StyledBackgroundModule>
      <LabelInput htmlFor="style">
        Mode
        <BackgroundMode
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
        />
      </LabelInput>
      {selectedModule.style?.backgroundType !== "image" && (
        <LabelInput htmlFor="">
          Colors
          <ContainerInputColor color>
            <div className="wrapper-collor-btn">
              <Picker
                values={
                  selectedModule.style?.backgroundColors || {
                    primary: "whitesmoke",
                    secondary: "#ffffff",
                  }
                }
                onChange={handleChangeColor}
                options={{
                  title: "Primary",
                  position: "center",
                  key: "primary",
                }}
              />
              <div
                hidden={
                  selectedModule.style?.backgroundType === "full" ||
                  !selectedModule.style?.backgroundType ||
                  selectedModule.style?.backgroundType === "image"
                }
              >
                <Picker
                  values={
                    selectedModule.style?.backgroundColors || {
                      primary: "whitesmoke",
                      secondary: "#ffffff",
                    }
                  }
                  onChange={handleChangeColor}
                  options={{
                    title: "Secondary",
                    position: "center",
                    key: "secondary",
                  }}
                />
              </div>
            </div>
          </ContainerInputColor>
        </LabelInput>
      )}
      {selectedModule.style?.backgroundType === "image" && (
        <ImageUpload
          title="Image"
          initialPreview={
            selectedModule.style?.blob
              ? URL.createObjectURL(selectedModule.style.blob)
              : selectedModule.style?.backgroundImage || ""
          }
          setBlob={(blob) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, blob },
            })
          }
          ratio={selectedModule.style.width === "double" ? 8 / 3 : 4 / 3}
        />
      )}
    </StyledBackgroundModule>
  );
}
