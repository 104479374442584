import { functionsFetch } from "../utils/functionsFetch";

export const getAllWidgets = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/widgets`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
