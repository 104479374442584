import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modale } from "../../../../components/Modale";
import {
  Input,
  LabelInput,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { createGroup } from "../../../../modules/taxiGroups";
import { fetchTaxiGroups } from "../../../../store/TaxiGroups/features";

export const GroupModal = ({ close, ...props }) => {
  const [inputGroup, setInputGroup] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (checkValidity()) {
      try {
        await createGroup(inputGroup);
        dispatch(fetchTaxiGroups());
        close();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const checkValidity = () => {
    let isValid = true;
    if (inputGroup.length < 3) isValid = false;
    if (inputGroup.length > 10) isValid = false;
    return isValid;
  };

  return (
    <Modale
      title="Create group"
      close={close}
      actions={[
        {
          title: "Create",
          click: onSubmit,
        },
      ]}
      {...props}
    >
      <LabelInput style={{ minWidth: "300px" }} htmlFor="group">
        Group name
        <Input
          type="text"
          id="group"
          placeholder="Name your group"
          value={inputGroup}
          onChange={(e) => setInputGroup(e.target.value)}
          name="group"
        />
      </LabelInput>
    </Modale>
  );
};
