import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CardWithTabs } from "../../../../components/CardWithTabs";
import { updateCampaignById } from "../../../../modules/campaigns";
import { campaignsSelector } from "../../../../store/Campaigns";
import { CampaignMedias } from "./components/CampaignMedias";
import { CampaignSettings } from "./components/CampaignSettings";
import { CampaignTarget } from "./components/CampaignTarget";

const checkCampaignValidity = (campaign) => {
  if (!campaign.name) {
    toast.error("Please add name in campaign settings");
    return false;
  }
  if (!campaign.userId) {
    toast.error("Please add user id in campaign settings");
    return false;
  }
  return true;
};

export default function Campaign() {
  const { id } = useParams();
  const { campaigns } = useSelector(campaignsSelector);
  const [selectedCampaign, setSelectedCampaign] = useState(
    campaigns.find((campaign) => campaign.id === id) || {
      name: "",
      campaignDate: true,
      startDate: null,
      endDate: null,
      targetGroups: [],
      locations: [],
      banner: { clicks: 0, src: null },
      video: { clicks: 0, src: null },
      dailyBudget: null,
      totalBudget: null,
      whitelabel: "",
      userId: "",
      whitelabelLayout: null,
    }
  );

  const handleSaveCampaign = async () => {
    if (checkCampaignValidity(selectedCampaign)) {
      await updateCampaignById(selectedCampaign.id, {
        ...selectedCampaign,
        startDate: { value: selectedCampaign.startDate },
        endDate: { value: selectedCampaign.endDate },
      });
    }
  };

  const tabs = [
    {
      name: "Settings",
      component: (
        <CampaignSettings
          createCampaign={id === "create"}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          handleSaveCampaign={handleSaveCampaign}
        />
      ),
    },
    {
      name: "Target",
      component: (
        <CampaignTarget
          createCampaign={id === "create"}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          handleSaveCampaign={handleSaveCampaign}
        />
      ),
    },
    {
      name: "Medias",
      component: (
        <CampaignMedias
          createCampaign={id === "create"}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          handleSaveCampaign={handleSaveCampaign}
        />
      ),
    },
  ];

  return (
    <CardWithTabs
      name={`Campaign «${selectedCampaign?.name || "untitled"}»`}
      tabs={tabs}
    />
  );
}
