import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import Geocode from "react-geocode";
import Map from "./Map/Map";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  // Slider,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxiGroups } from "../../../../../store/TaxiGroups/features";
import { fetchWhitelabelList } from "../../../../../store/Whitelabel/WhitelabelList/features";
import { whitelabelListSelector } from "../../../../../store/Whitelabel/WhitelabelList";
import { Slider } from "./Slider/Slider";

const getPosition = async (location) => {
  try {
    Geocode.setApiKey("AIzaSyChTU-YnwyAOAWn8nxXp-0t2rlOxEM9LIs");
    const res = await Geocode.fromAddress(location);
    const { lat, lng } = res.results[0].geometry.location;
    return {
      lat,
      lng,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

const Location = styled.div`
  width: 100%;
  display: flex;
  margin: 8px 0;
  gap: 8px;
  align-items: center;
  * {
    margin: 0;
    padding: 0;
  }
`;

const ContainerMap = styled.div`
  width: 100%;
  height: calc(100vh - 420px);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  .leaflet-bottom {
    visibility: hidden;
  }
`;

const WrapperSlider = styled.div`
  width: 100%;
  margin-bottom: 12px;
  .slider span.label {
    width: 130px !important;
    font-size: 12px;
    font-family: Work Sans, sans-serif;
  }
`;
const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
    margin-bottom: 8px;
  }
`;

export function CampaignTarget({
  selectedCampaign,
  setSelectedCampaign,
  handleSaveCampaign,
  createCampaign,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const taxiGroups = useSelector((state) => state.taxiGroups.taxiGroups);
  const { whiteLabelList } = useSelector(whitelabelListSelector);
  const [locations, setLocations] = useState([]);
  const [mapInitial, setMapInitial] = useState({
    lat: 48.856613,
    lng: 2.352222,
    zoom: 13,
  });

  const locationsAdmin = useMemo(() => {
    return (
      whiteLabelList.find(
        (whitelabel) => whitelabel.slug === selectedCampaign.whitelabel
      )?.locations || []
    );
  }, [selectedCampaign.whitelabel, whiteLabelList]);

  useEffect(() => {
    dispatch(fetchTaxiGroups());
    dispatch(fetchWhitelabelList());
  }, []);
  
  const createArea = ['Paris', 'Dubai', 'Monaco', 'New-York']
  useEffect(() => {
    if (createCampaign) {
      setLocations(
        createArea.reduce(
          (acc, label) => ({
            ...acc,
            [label]:
              selectedCampaign.locations.find(
                (location) => location.label === label
              )?.value ?? false,
          }),
          {}
        )
      );
    } else {
      setLocations(
        locationsAdmin.reduce(
          (acc, label) => ({
            ...acc,
            [label]:
              selectedCampaign.locations.find(
                (location) => location.label === label
              )?.value ?? false,
          }),
          {}
        )
      );
    }
  }, [selectedCampaign, locationsAdmin]);

  const changeMap = async (location) => {
    let pos;
    pos = location.pos || (await getPosition(location.label));
    if (!pos) alert("Cette localisation n'est plus disponible pour le moment");
    setMapInitial((map) => ({ ...map, ...pos }));
    return pos;
  };

  const handleCheck = async (e) => {
    const { checked: value, id: label } = e.target;

    if (!value) {
      return setSelectedCampaign((campaign) => ({
        ...campaign,
        locations: campaign.locations.filter((key) => key.label !== label),
      }));
    }
    const pos = await changeMap(
      selectedCampaign.locations.find((x) => x.label === label) || { label }
    );
    if (!pos) return;

    return setSelectedCampaign((campaign) => ({
      ...campaign,
      locations: [
        ...campaign.locations,
        {
          label,
          value,
          pos,
          range: 1000,
        },
      ],
    }));
  };

  const handleRange = async (e) => {
    const { value, name } = e.target;
    setSelectedCampaign((campaign) => ({
      ...campaign,
      locations: campaign.locations.map((location) => {
        if (location.label !== name) return location;
        return {
          ...location,
          range: value,
        };
      }),
    }));
  };

  const eventHandlers = (name, markerRef) => ({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        setSelectedCampaign((campaign) => ({
          ...campaign,
          locations: campaign.locations.map((location) => {
            if (location.label !== name) return location;
            return {
              ...location,
              pos: { ...marker.getLatLng() },
            };
          }),
        }));
      }
    },
  });

  return (
    <>
      <LabelInput htmlFor="name">
        Targets
        <Select
          labelId="targets"
          id="targets"
          multiple
          value={selectedCampaign.targetGroups}
          onChange={(e) =>
            setSelectedCampaign({
              ...selectedCampaign,
              targetGroups: e.target.value,
            })
          }
          input={<OutlinedInput id="targets" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {taxiGroups[0] &&
            taxiGroups.map((group, index) => (
              <MenuItem key={index} value={group.name}>
                {group.name}
              </MenuItem>
            ))}
        </Select>
      </LabelInput>
      <WrapperTitle>
        <span>Define your area</span>
        <small>Select your locations.</small>
      </WrapperTitle>
      <LabelInput htmlFor="name">
        {Object.entries(locations).map(([label, value]) => {
          return (
            <Location>
              <Checkbox id={label} checked={value} onChange={handleCheck} />
              <p>{label}</p>
            </Location>
          );
        })}
      </LabelInput>
      {selectedCampaign.locations.find((location) => location.value) && (
        <WrapperSlider>
          <div>
            <small>Define a radius</small>
          </div>
          {selectedCampaign.locations.map((location, i) => {
            if (!location.value) return null;
            return (
              <Slider
                key={i}
                wrapperStyle={i === 0 ? { marginTop: "12px" } : null}
                onClick={async () => changeMap(location)}
                step={10}
                min={0}
                max={10000}
                name={location.label}
                value={location.range}
                onChange={handleRange}
                unit="m"
                suffix={location.label + " :"}
                noValuePossible
              />
            );
          })}
        </WrapperSlider>
      )}
      <ContainerMap>
        <Map
          eventHandlers={eventHandlers}
          mapInitial={mapInitial}
          locations={selectedCampaign.locations}
          locationsAdmin={locationsAdmin}
        />
      </ContainerMap>
      {!createCampaign && (
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis?tab=campaigns")}>
            Back
          </Button>
          <Button onClick={() => handleSaveCampaign()}>Save</Button>
        </Buttons>
      )}
    </>
  );
}
