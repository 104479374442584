import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { createCampaignById } from "../../../../../modules/campaigns";
import Filestack from "./Filestack/Filestack";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../../firebase";
import { useDispatch } from "react-redux";
import { fetchAllCampaigns } from "../../../../../store/Campaigns/features";
import { toast } from "react-toastify";

const checkCampaignValidity = (campaign) => {
  if (!campaign.name) {
    toast.error("Please add name in campaign settings");
    return false;
  }
  if (!campaign.userId) {
    toast.error("Please add user id in campaign settings");
    return false;
  }
  return true;
};

const MediasPreview = styled.div`
  width: calc(100% - 2px);
  aspect-ratio: 16/9;
  background-color: lightgray;
  border-radius: 12px;
  display: flex;
  border: solid black 1px;
  overflow: hidden;
  .left {
    flex-grow: 1;
    background-color: read-only;
    .video-container {
      height: ${({ layout }) => (layout === "SPLIT" ? "63.33%" : "100%")};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f2f6ff;
      position: relative;
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .banner-container {
      height: ${({ layout }) => (layout === "SPLIT" ? "36.67%" : "100%")};
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 1px solid black;
      background-color: #f2f6ff;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .right {
    width: 33.333%;
    border-left: 1px solid black;
    background-color: #fdf3f2;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;
const BtnUpload = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  /* opacity: 0.75; */
  -webkit-transition: 600ms;
  transition: 600ms;
  visibility: visible;
  /* z-index: 2; */
`;

export function CampaignMedias({
  selectedCampaign,
  handleSaveCampaign,
  createCampaign,
  setSelectedCampaign,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const layout = useMemo(() => {
    return selectedCampaign.layout || selectedCampaign.whitelabelLayout;
  }, [selectedCampaign]);

  const formatMedia = async (media, type) => {
    if (!media.blob) return media;
    try {
      const date = Date.now();
      const metadata = {
        contentType: media.blob?.type,
      };

      const imageRef = ref(storage, `taxi_campaigns/${type}/${date}`);
      await uploadBytes(imageRef, media.blob, metadata);

      const src = await getDownloadURL(imageRef);
      return {
        clicks: media.clicks,
        src,
      };
    } catch (err) {
      console.log(err);
      return {
        clicks: media.clicks,
        src: "",
      };
    }
  };

  const handleSubmitCampaign = async () => {
    if (checkCampaignValidity(selectedCampaign) === true) {
      try {
        const formattedDataRequest = {
          name: selectedCampaign.name,
          campaignDate: selectedCampaign.campaignDate,
          startDate: { value: selectedCampaign.startDate },
          endDate: { value: selectedCampaign.endDate },
          targetGroups: selectedCampaign.targetGroups,
          locations: selectedCampaign.locations,
          banner: await formatMedia(selectedCampaign.banner, "images"),
          video: await formatMedia(selectedCampaign.video),
          dailyBudget: selectedCampaign.dailyBudget,
          totalBudget: selectedCampaign.totalBudget,
          whitelabel: selectedCampaign.whitelabel,
          userId: selectedCampaign.userId,
        };

        await createCampaignById(formattedDataRequest);
        dispatch(fetchAllCampaigns());
        navigate("/dashboard/taxis?tab=campaigns");
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <>
      <MediasPreview layout={layout}>
        <div className="left">
          {(layout === "SPLIT" || layout === "VIDEO") && (
            <div className="video-container">
              {selectedCampaign.video?.src && (
                <video autoPlay loop muted>
                  <source src={selectedCampaign.video?.src} type="video/mp4" />
                </video>
              )}
              <BtnUpload>
                <Filestack
                  size={100}
                  aspectRatio={
                    selectedCampaign.whitelabelLayout === "SPLIT"
                      ? 1180 / 630
                      : 1180 / 996
                  }
                  accept={"video/*"}
                  typeVideo={"video/mp4"}
                  urlIcon={
                    "https://img.icons8.com/material-rounded/24/null/plus--v1.png"
                  }
                  setValues={(val, blob) => {
                    setSelectedCampaign({
                      ...selectedCampaign,
                      video: { clicks: 0, src: val, blob: blob },
                    });
                  }}
                />
              </BtnUpload>
            </div>
          )}
          {(layout === "SPLIT" || layout === "BANNER") && (
            <div className="banner-container">
              {selectedCampaign.banner?.src && (
                <img
                  src={selectedCampaign.banner.src}
                  alt={selectedCampaign.label}
                />
              )}
              <BtnUpload>
                <Filestack
                  aspectRatio={layout === "SPLIT" ? 1180 / 336 : 1180 / 996}
                  urlIcon={
                    "https://img.icons8.com/material-rounded/24/null/plus--v1.png"
                  }
                  setValues={(val, blob) =>
                    setSelectedCampaign({
                      ...selectedCampaign,
                      banner: { clicks: 0, src: val, blob: blob },
                    })
                  }
                />
              </BtnUpload>
            </div>
          )}
        </div>
        <div className="right"></div>
      </MediasPreview>

      {!createCampaign && (
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis?tab=campaigns")}>
            Back
          </Button>
          <Button onClick={() => handleSaveCampaign()}>Save</Button>
        </Buttons>
      )}
      {createCampaign && (
        <Buttons>
          <Button onClick={() => handleSubmitCampaign()}>Save</Button>
        </Buttons>
      )}
    </>
  );
}
