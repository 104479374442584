import PeopleIcon from "@mui/icons-material/People";
import LabelIcon from "@mui/icons-material/Label";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import LinkIcon from "@mui/icons-material/Link";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import LocalTaxiOutlinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import Redeem from "@mui/icons-material/Redeem";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";

const navbarList = [
  {
    icon: AlignHorizontalLeftIcon,
    desc: "Global",
    link: "global",
    roles: ["admin"],
  },
  {
    icon: LinkIcon,
    desc: "Links",
    link: "links",
    roles: ["admin"],
  },
  {
    icon: PeopleIcon,
    desc: "Users",
    link: "users",
    roles: ["admin"],
  },
  {
    icon: LabelIcon,
    desc: "Whitelabels",
    link: "whitelabels",
    roles: ["admin"],
  },
  {
    icon: FormatListBulletedIcon,
    desc: "Orders",
    link: "orders",
    roles: ["admin"],
  },
  {
    icon: LoyaltyIcon,
    desc: "Program requests",
    link: "programRequests",
    roles: ["admin"],
  },
  {
    icon: Redeem,
    desc: "Benefits",
    link: "benefits",
    roles: ["admin"],
  },
  {
    icon: LocalTaxiOutlinedIcon,
    desc: "Taxis",
    link: "taxis",
    roles: ["admin", "taxi", "vendor"],
  },
  {
    icon: StyleOutlinedIcon,
    desc: "Business cards",
    link: "businessCards",
    roles: ["admin"],
  },
];

export default navbarList;
