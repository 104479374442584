import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllCampaigns } from "../../modules/campaigns"

export const fetchAllCampaigns = createAsyncThunk(
    "campaigns/fetchAllCampaigns",
    async (arg, thunkAPI) => {
        try {
            const responseData = await getAllCampaigns();
            return responseData;
        } catch (e) {
            console.log("Error", e.response.data);
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);