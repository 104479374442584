import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllGroups } from "../../modules/taxiGroups";

export const fetchTaxiGroups = createAsyncThunk(
  "taxiGroups/fetchtaxiGroups",
  async (arg, thunkAPI) => {
    try {
      const responseData = await getAllGroups();
      return responseData;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
