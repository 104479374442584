import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProductsModulesShop } from "../../modules/taxiModulesShop";

export const fetchTaxiModulesShop = createAsyncThunk(
  "taxiModulesShop/fetchTaxiModulesShop",
  async (arg, thunkAPI) => {
    try {
      const response = await getAllProductsModulesShop(arg);
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
