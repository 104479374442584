import { createSlice } from "@reduxjs/toolkit";
import { fetchAllListingItems } from "./features";

export const slice = createSlice({
  name: "moduleListingItem",
  initialState: {
    items: [],
    isLoading: false,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllListingItems.fulfilled, (state, { payload }) => {
        state.items = payload.data;
      })
      .addCase(fetchAllListingItems.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllListingItems.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default slice.reducer;
