import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProductsDevicesShop } from "../../modules/taxiDevicesShop";

export const fetchTaxideviceShop = createAsyncThunk(
    "taxideviceShop/fetchTaxideviceShop",
    async (arg, thunkAPI) => {
        try {
        const response = await getAllProductsDevicesShop(arg);
        return response;
    } catch (e) {
        console.log("Error", e.response.data);
        return thunkAPI.rejectWithValue(e.response.data);
    }
    }
);