import { Slider } from "@mui/material";
import styled from "styled-components";
import { Picker } from "../../../../../components/ModalWhiteLabel/components/Picker";
import {
  ContainerInputColor,
  LabelInput,
  Select,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";

const StyledModuleDisplay = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  label {
    width: calc(50% - 16px);
  }
  user-select: none;
`;

export function ModuleDisplay({ selectedModule, setSelectedModule }) {
  return (
    <StyledModuleDisplay>
      <LabelInput htmlFor="width">
        Width
        <Select
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, width: e.target.value },
            })
          }
          value={selectedModule.style?.width || "simple"}
          name="width"
        >
          <option value={"simple"}>Simple</option>
          <option value={"double"}>Double</option>
        </Select>
      </LabelInput>
      <LabelInput htmlFor="corner-radius">
        Corner radius
        <Slider
          name="corner-radius"
          min={0}
          max={24}
          step={1}
          value={
            selectedModule.style?.cornerRadius
              ? selectedModule.style.cornerRadius.slice(0, -2)
              : 12
          }
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: {
                ...selectedModule.style,
                cornerRadius: e.target.value + "px",
              },
            })
          }
        />
      </LabelInput>
      <LabelInput htmlFor="border-size">
        Border size
        <Slider
          name="border-size"
          min={0}
          max={5}
          step={1}
          value={selectedModule.style?.borderSize || 0}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: {
                ...selectedModule.style,
                borderSize: e.target.value,
              },
            })
          }
        />
      </LabelInput>
      <LabelInput htmlFor="">
        <ContainerInputColor color>
          Color
          <div className="wrapper-collor-btn">
            <Picker
              values={{
                borderColor: selectedModule.style?.borderColor || "whitesmoke",
              }}
              onChange={(color, key) =>
                setSelectedModule({
                  ...selectedModule,
                  style: {
                    ...selectedModule.style,
                    borderColor: color,
                  },
                })
              }
              options={{
                title: "Border Color",
                position: "center",
                key: "borderColor",
              }}
            />
          </div>
        </ContainerInputColor>
      </LabelInput>
    </StyledModuleDisplay>
  );
}
