import { CloseOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import types from "./types";
import styled from "styled-components";

const StyledResource = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${(props) =>
    props.index % 2 === 0 ? "whitesmoke" : "white"};
  padding: 0 8px;
  height: 48px;
  border-top: 1px solid lightGrey;
  select,
  input {
    border: none;
    outline: none;
    width: 100px;
    background-color: transparent;
  }
  input {
    text-align: end;
  }
`;

const Resource = ({ index, type, value, onChange, onDelete }) => {
  return (
    <StyledResource index={index}>
      <select
        value={type}
        onChange={(e) => onChange({ type: e.target.value, value })}
      >
        {types.map((type) => (
          <option key={type} value={type}>
            {capitalizeFirstLetter(type)}
          </option>
        ))}
      </select>
      <input
        type="number"
        value={value}
        onChange={(e) => onChange({ type, value: Number(e.target.value) })}
      />
      <Button>
        <CloseOutlined
          onClick={onDelete}
          style={{
            cursor: "pointer",
            color: "white",
          }}
        />
      </Button>
    </StyledResource>
  );
};

export default Resource;
