import { Slider } from "@mui/material";
import styled from "styled-components";
import { Picker } from "../../../../../components/ModalWhiteLabel/components/Picker";
import {
  ContainerInputColor,
  Input,
  LabelInput,
  Select,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";

const StyledTitleModule = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  label {
    width: calc(50% - 16px);
  }
  user-select: none;
`;

const fonts = [
  {
    family: "Custom",
    names: ["Roboto", "Work Sans", "Poppins"],
  },
  {
    family: "Serif",
    names: ["Times New Roman", "Georgia", "Garamond"],
  },
  {
    family: "Sans-serif",
    names: ["Arial", "Verdana", "Helvetica"],
  },
  {
    family: "Monospace",
    names: ["Consolas", "Lucida Console", "monospace"],
  },
];

export function ModuleTitle({ selectedModule, setSelectedModule }) {
  return (
    <StyledTitleModule>
      <LabelInput htmlFor="title">
        Title
        <Input
          type="text"
          id="title"
          placeholder="Enter a title for this module"
          value={selectedModule.style?.title ?? selectedModule.title}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, title: e.target.value },
            })
          }
          name="title"
        />
      </LabelInput>
      <LabelInput htmlFor="font">
        Font
        <Select
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, font: e.target.value },
            })
          }
          value={selectedModule.style?.font || "Verdana"}
          name="font"
        >
          {fonts.map((group) => {
            return (
              <optgroup key={group.family} label={group.family}>
                {group.names.map((name) => {
                  return (
                    <option key={group.family + "-" + name} value={name}>
                      {name}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </Select>
      </LabelInput>
      <LabelInput htmlFor="font-size">
        Size
        <Slider
          name="font-size"
          min={12}
          max={32}
          step={2}
          value={selectedModule.style?.fontSize || 22}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, fontSize: e.target.value },
            })
          }
        />
      </LabelInput>
      <LabelInput htmlFor="text-shadow-opacity">
        Shadow
        <Slider
          name="text-shadow-opacity"
          min={0}
          max={0.9}
          step={0.1}
          value={selectedModule.style?.textShadowOpacity || 0}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: {
                ...selectedModule.style,
                textShadowOpacity: e.target.value,
              },
            })
          }
        />
      </LabelInput>
      <LabelInput htmlFor="">
        <ContainerInputColor color>
          Color
          <div className="wrapper-collor-btn">
            <Picker
              values={{
                textColor: selectedModule.style?.textColor || "#000000",
              }}
              onChange={(color, key) =>
                setSelectedModule({
                  ...selectedModule,
                  style: {
                    ...selectedModule.style,
                    textColor: color,
                  },
                })
              }
              options={{
                title: "Text Color",
                position: "center",
                key: "textColor",
              }}
            />
          </div>
        </ContainerInputColor>
      </LabelInput>
    </StyledTitleModule>
  );
}
