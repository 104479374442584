import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersList } from "./features";

export const usersList = createSlice({
  name: "usersList",
  initialState: {
    usersList: [],
    isLoading: true,
    isError: true,
  },
  reducers: {
    updateUserByUID: (state, { payload }) => {
      const list = state.usersList.map((user) => {
        if (user.uid === payload.uid) {
          console.log(payload.data);
          return {
            ...user,
            ...payload.data,
          };
        }
        return user;
      });
      state.usersList = list;
    },
    deleteUserByUID: (state, { payload }) => {
      const list = state.usersList.filter((user) => user.uid !== payload.uid);
      state.usersList = list;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.fulfilled, (state, { payload }) => {
        state.usersList = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchUsersList.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchUsersList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default usersList.reducer;

export const usersListSelector = (state) => state.usersList;
export const { updateUserByUID, deleteUserByUID } = usersList.actions;
