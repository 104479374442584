import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllListings } from "../../modules/taxiModuleListings";

export const fetchAllListings = createAsyncThunk(
  "taxiModuleListing/fetchAllListings",
  async (arg, thunkAPI) => {
    try {
      const responseData = await getAllListings();
      return responseData;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
