import * as React from "react";
import { useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { whitelabelListSelector } from "../../../store/Whitelabel/WhitelabelList";
import { Global } from "../../../Panel/Global";
import { linksSelector } from "../../../store/Links";
import { ordersListSelector } from "../../../store/Orders/OrderList";
import { usersCountSelector } from "../../../store/Users/Count";
import { fetchUsersCountByWhitelabel } from "../../../store/Users/Count/features";

export default function Users() {
  const dispatch = useDispatch();
  const { links } = useSelector(linksSelector);
  const { usersCount, usersCountByWhitelabel } =
    useSelector(usersCountSelector);
  const { ordersList } = useSelector(ordersListSelector);
  const [filteredOrders, setFilteredOrders] = useState(ordersList);
  const [filteredLinks, setFilteredLinks] = useState(links);

  const { whiteLabelList, selectedWhiteLabel } = useSelector(
    whitelabelListSelector
  );

  const filterData = (list, action) => {
    action(
      list.filter((x) => {
        if (!selectedWhiteLabel || selectedWhiteLabel === "All whitelabels") {
          return true;
        }
        if (!x.whiteLabel) {
          return false;
        }
        return selectedWhiteLabel.toLowerCase() === x.whiteLabel.toLowerCase();
      })
    );
  };

  useEffect(() => {
    filterData(
      ordersList.map((x) => ({ ...x, whiteLabel: x.whitelabel })),
      setFilteredOrders
    );
    filterData(
      links.map((link) => ({ ...link, whiteLabel: link.whitelabel })),
      setFilteredLinks
    );
  }, [ordersList, links, usersCount, selectedWhiteLabel]);

  useEffect(() => {
    if (selectedWhiteLabel !== "All whitelabels") {
      dispatch(fetchUsersCountByWhitelabel({ whitelabel: selectedWhiteLabel }));
    }
  }, [selectedWhiteLabel, dispatch]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader title={"Global"} />
        <Global>
          <span>
            <small>TOTAL USERS: </small>
            {selectedWhiteLabel === "All whitelabels"
              ? usersCount
              : usersCountByWhitelabel}
          </span>
          <span>
            <small>TOTAL WHITELABELS : </small>
            {whiteLabelList.length}
          </span>
          <span>
            <small>TOTAL LINKS NOT USED : </small>
            {filteredLinks.filter((link) => !link.isUsed).length}
          </span>
          <span>
            <small>TOTAL ORDERS : </small>
            {filteredOrders.length}
          </span>
        </Global>
      </Card>
    </React.Fragment>
  );
}
