import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modale } from "../../../../components/Modale";
import {
  Input,
  LabelInput,
  Select,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { createDevice } from "../../../../modules/taxiDevices";
import { fetchTaxiDevices } from "../../../../store/TaxiDevices/features";

export const DeviceModal = ({ close, ...props }) => {
  const { taxiGroups: groups } = useSelector((state) => state.taxiGroups);
  const [device, setDevice] = useState({
    code: "",
    name: "",
    layout: "SQUARE",
    groupId: groups[0]?.id,
  });
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (checkValidity()) {
      try {
        await createDevice(device);
        close();
        dispatch(fetchTaxiDevices());
        setDevice({
          code: "",
          name: "",
          layout: "SQUARE",
          groupiId: groups[0]?.id,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const checkValidity = () => {
    let isValid = true;
    if (device.code.length < 3) isValid = false;
    if (device.name.length < 3) isValid = false;
    return isValid;
  };

  return (
    <Modale
      title="Create device"
      close={close}
      actions={[
        {
          title: "Submit",
          click: onSubmit,
        },
      ]}
      {...props}
    >
      <LabelInput style={{ minWidth: "300px" }} htmlFor="code">
        Code
        <Input
          type="text"
          id="code"
          placeholder="Enter the code shown on the tablet"
          value={device.code}
          onChange={(e) => setDevice({ ...device, code: e.target.value })}
          name="code"
        />
      </LabelInput>
      <LabelInput style={{ minWidth: "300px" }} htmlFor="name">
        Name
        <Input
          type="text"
          id="name"
          placeholder="Name your device"
          value={device.name}
          onChange={(e) => setDevice({ ...device, name: e.target.value })}
          name="name"
        />
      </LabelInput>
      <LabelInput style={{ minWidth: "300px" }} htmlFor="groupId">
        Group
        <Select
          onChange={(e) => setDevice({ ...device, groupId: e.target.value })}
          value={device.groupId}
          name="groupId"
        >
          {groups?.map((group) => {
            return (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            );
          })}
        </Select>
      </LabelInput>
    </Modale>
  );
};
