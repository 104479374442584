import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiGroups } from "./features";

export const taxiGroups = createSlice({
    name: "taxiGroups",
    initialState: {
        taxiGroups: [],
        isLoading: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTaxiGroups.fulfilled, (state, { payload }) => {
                state.taxiGroups = payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchTaxiGroups.pending, (state, { payload }) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchTaxiGroups.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default taxiGroups.reducer;

export const taxiGroupsSelector = (state) => state.taxiGroups;
