import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modale } from "../../../../components/Modale";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import styled from "styled-components";
import { ModulePreview } from "../Module/components/ModulePreview";

const ModulesPreview = styled.div`
  display: flex;
  width: 800px;
  aspect-ratio: 16/9;
  border-radius: 12px;
  overflow: hidden;
  .left {
    flex-grow: 1;
    background-color: #ffbab89a;
  }
  .modules {
    width: 33.33%;
    background-color: #000000;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    grid-template-rows: min-content;
    gap: 4px;
    padding: 4px;
    overflow-y: scroll;
  }
`;

export const ModulesPreviewModal = ({ close, groupId, ...props }) => {
  const { value: modules, loading } = useSelector((state) => state.taxiModules);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaxiModules());
  }, []);

  const displayablesModules = () => {
    const visibles = modules.filter((module) => {
      return module.active && module.groupId === groupId;
    });
    return visibles.sort((a, b) => {
      return a.rank - b.rank;
    });
  };

  return (
    <Modale title="Modules preview" close={close} {...props} actions={[]}>
      <ModulesPreview>
        <div className="left" />
        <div className="modules">
          {displayablesModules().map((module, i) => {
            return (
              <ModulePreview
                key={i}
                selectedModule={{
                  ...module,
                  style: {
                    ...module.style,
                    fontSize: (module.style?.fontSize || 18) * 4 + "%",
                    iconSize: (module.style?.iconSize || 32) * 0.5,
                  },
                }}
                preview
              />
            );
          })}
        </div>
      </ModulesPreview>
    </Modale>
  );
};
