import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Dashboard/Users";
import Global from "./pages/Dashboard/Global";
import Products from "./pages/Dashboard/Products";
import Links from "./pages/Dashboard/Links";
import Orders from "./pages/Dashboard/Orders";
import ProgramRequests from "./pages/Dashboard/ProgramRequests";
import Taxis from "./pages/Dashboard/Taxis";
import Device from "./pages/Dashboard/Taxis/Device";
import WhiteLabels from "./pages/Dashboard/WhiteLabels";
import Benefits from "./pages/Dashboard/Benefits";
import Module from "./pages/Dashboard/Taxis/Module";
import Campaign from "./pages/Dashboard/Taxis/Campaign";
import WhiteLabel from "./pages/Dashboard/WhiteLabels/WhiteLabel";
import BusinessCard from "./pages/Dashboard/BusinessCards";

export default function App() {
  let navigate = useNavigate();
  const userRole = JSON.parse(sessionStorage.getItem("user"))?.role;

  useEffect(() => {
    (async () => {
      let data = JSON.parse(window.sessionStorage.getItem("user"));
      if (data) {
        if (data.role === "admin") navigate("dashboard/global");
        if (data.role === "vendor" || data.role === "taxi")
          navigate("dashboard/taxis");
        if (data.role === "sponsor") navigate("dashboard/taxis?tab=modules");
      }
    })();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="dashboard" element={<Dashboard />}>
        {userRole === "admin" && (
          <>
            <Route path="whitelabels" element={<WhiteLabels />} />
            <Route path="whitelabels/:id" element={<WhiteLabel />} />
            <Route path="users" element={<Users />} />
            <Route path="benefits" element={<Benefits />} />
            <Route path="global" element={<Global />} />
            <Route path="links" element={<Links />} />
            <Route path="products" element={<Products />} />
            <Route path="orders" element={<Orders />} />
            <Route path="programRequests" element={<ProgramRequests />} />
            <Route path="businessCards" element={<BusinessCard />} />
          </>
        )}
        <Route path="taxis" element={<Taxis />} />
        {(userRole === "admin" ||
          userRole === "vendor" ||
          userRole === "sponsor") && (
          <Route path="taxis/module/:id" element={<Module />} />
        )}
        {(userRole === "admin" ||
          userRole === "taxi" ||
          userRole === "vendor") && (
          <Route path="taxis/device/:id" element={<Device />} />
        )}
        {userRole === "admin" && (
          <>
            <Route path="taxis/campaign/:id" element={<Campaign />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
