import { functionsFetch } from "../utils/functionsFetch";

export const getAllBusinessCardTemplates = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/business-card-templates`,
    });

    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateBusinessCardTemplatesById = async ({ id, data }) => {
  try {
    const response = await functionsFetch({
      method: "PUT",
      url: `/business-card-templates/${id}`,
      data,
    });
    if (response.data.status === 404) {
      return Promise.reject({
        message: data.message,
      });
    }
  } catch (err) {
    console.log(err);
    alert(err.response.data.message);
    return Promise.reject(err.response.data);
  }
};
