import * as React from "react";

function IconBrandVsco({
  size = 24,
  color = "currentColor",
  stroke = 2,
  ...props
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-vsco" width={size} height={size} viewBox="0 0 24 24" strokeWidth={stroke} stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><g transform="translate(2 2)"><circle cx={10} cy={10} r={10} /><circle cx={10} cy={10} r={7} /><circle cx={10} cy={10} r={4} /><path d="M10 0v3" /><path d="M10 17v3" /><path d="M17 10h3" /><path d="M0 10h3" /><path d="M15 1.34l-1.5 2.598" /><path d="M6.5 16.062l-1.5 2.598" /><path d="M16.062 13.5l2.598 1.5" /><path d="M1.34 5l2.598 1.5" /><path d="M5 1.34l1.5 2.598" /><path d="M13.5 16.062l1.5 2.598" /><path d="M16.062 6.5l2.598 -1.5" /><path d="M1.34 15l2.598 -1.5" /><path d="M10 3v3" /><path d="M10 14v3" /><path d="M3 10h3" /><path d="M14 10h3" /><path d="M14.95 5.05l-2.122 2.122" /><path d="M7.172 12.828l-2.122 2.122" /><path d="M5.05 5.05l2.122 2.122" /><path d="M12.828 12.828l2.122 2.122" /></g></svg>;
}

export default IconBrandVsco;