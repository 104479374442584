import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modale } from "../../../../components/Modale";
import {
  Input,
  LabelInput,
  Select,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { createModule } from "../../../../modules/taxiModules";
import { taxiWidgetsSelector } from "../../../../store/TaxiWidgets";
import { fetchTaxiWidgets } from "../../../../store/TaxiWidgets/features";
import { toast } from "react-toastify";
import { checkIsUrl } from "../../../../utils/util";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";

export const ModuleModal = ({ close, selectedModuleGroup, ...props }) => {
  const [module, setModule] = useState({ title: "", type: "qrcode" });
  const [url, setUrl] = useState("");
  const [widgetId, setWidgetId] = useState("");
  const { value: widgets } = useSelector(taxiWidgetsSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (!selectedModuleGroup) return alert("Please select a module group");
    if (checkValidity()) {
      setLoading(true);
      try {
        const newModule = module;
        newModule.type === "qrcode"
          ? (newModule.url = url)
          : (newModule.widgetId = widgetId);
        await createModule({ ...newModule, groupId: selectedModuleGroup.id });
        close();
        setModule({ title: "", type: "qrcode" });
        setWidgetId("");
        setUrl("");
        dispatch(fetchTaxiModules());
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
  };

  const checkValidity = () => {
    let errors = [];
    if (module.title.length < 3)
      errors.push("You should add a title with at least 3 characters");
    if (module.type === "qrcode") {
      if (!checkIsUrl(url)) errors.push("You should add a valid url");
    }
    errors.forEach((error) => toast.error(error));
    return errors.length === 0;
  };

  useEffect(() => {
    dispatch(fetchTaxiWidgets());
  }, []);

  useEffect(() => {
    const defaultWidget = widgets[0];
    if (defaultWidget) setWidgetId(defaultWidget);
  }, [widgets]);

  return (
    <Modale
      title="Create a module"
      close={close}
      actions={[
        {
          title: "Submit",
          click: onSubmit,
          disabled: loading,
        },
      ]}
      {...props}
    >
      <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
        Title
        <Input
          type="text"
          id="title"
          placeholder="Enter a title for this module"
          value={module.title}
          onChange={(e) => setModule({ ...module, title: e.target.value })}
          name="title"
        />
      </LabelInput>
      <LabelInput style={{ minWidth: "300px" }} htmlFor="name">
        Type
        <Select
          onChange={(e) => setModule({ ...module, type: e.target.value })}
          value={module.type}
          name="type"
        >
          <option value={"qrcode"}>QR Code</option>
          <option value={"widget"}>Widget</option>
        </Select>
      </LabelInput>
      <LabelInput
        style={{ minWidth: "300px" }}
        htmlFor="url"
        hidden={module.type !== "qrcode"}
      >
        URL
        <Input
          type="url"
          id="url"
          placeholder="Enter an url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          name="url"
        />
      </LabelInput>
      <LabelInput
        style={{ minWidth: "300px" }}
        htmlFor="widget"
        hidden={module.type !== "widget"}
      >
        Widget
        <Select
          onChange={(e) => setWidgetId(e.target.value)}
          value={widgetId}
          name="widget"
        >
          {widgets.map((widget, index) => {
            return (
              <option key={index} value={widget.id}>
                {widget.name}
              </option>
            );
          })}
        </Select>
      </LabelInput>
    </Modale>
  );
};
