import { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxiDevices } from "../../../../store/TaxiDevices/features";
import { Status } from "../../../../components/Status";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  collection,
  query,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { LastOnline } from "../../../../components/LastOnline";

const CursorHoverTr = styled.div`
  tr {
    cursor: pointer;
  }
`;

const getStatusType = (lastOnline) => {
  if (!lastOnline) return "refused";
  if (Date.now() - parseInt(lastOnline || "0") > 70000) return "refused";
  return "accepted";
};

const getStatus = (lastOnline) => {
  if (!lastOnline) return "Offline";
  if (Date.now() - parseInt(lastOnline || "0") > 70000) return "Offline";
  return "Online";
};

const DeviceStatus = ({ lastOnline }) => {
  const [statusType, setStatusType] = useState(getStatusType(lastOnline));
  const [status, setStatus] = useState(getStatus(lastOnline));
  const [refreshInterval, setRefreshInterval] = useState();
  useEffect(() => {
    setStatusType(getStatusType(lastOnline));
    setStatus(getStatus(lastOnline));
    clearInterval(refreshInterval);
    setRefreshInterval(
      setInterval(() => setStatusType(getStatusType(lastOnline)), 30000)
    );

    return () => clearInterval(refreshInterval);
  }, [lastOnline]);
  return <Status status={statusType}>{status}</Status>;
};

export const DevicesTable = () => {
  const [dataTable, setDataTable] = useState([]);
  const [devices, setdevices] = useState([]);
  const groups = useSelector((state) => state.taxiGroups.taxiGroups);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const q = query(collection(db, "taxi_devices"));
    onSnapshot(q, (querySnapshot) => {
      const dbDevices = [];
      querySnapshot.forEach((doc) => {
        dbDevices.push(doc.data());
      });
      setdevices([...dbDevices]);
    });
  }, [db]);

  const fetchData = () => {
    const columnNames = [
      "Status",
      "Name",
      "Group",
      "Created_At",
      "Last_Online",
      "Active",
    ];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    const rows = [];
    if (devices[0]) {
      let devicesToDisplay = devices;
      if (user.role === "taxi")
        devicesToDisplay = [...devicesToDisplay].filter((device) => {
          return device.accountUid === user.user.uid.toString();
        });

      devicesToDisplay
        .filter((device) => (user.role !== "admin" ? device.shopId : true))
        .map((device, id) => {
          //We find the name of device's groupId
          let groupName = "";
          groups.forEach((group) => {
            if (group.id === device.groupId) {
              groupName = group.name;
            }
          });

          return rows.push({
            id,
            status: <DeviceStatus lastOnline={device.lastOnline} />,
            name: device.name,
            group: groupName,
            created_at: format(device.createdAt, "MM/dd/yyyy HH:mm"),
            last_online: <LastOnline lastOnline={device.lastOnline} />,
            active: device.active ? "Yes" : "No",
            clickEvent: () => navigate("/dashboard/taxis/device/" + device.id),
          });
        });
    }
    setDataTable({ columns, rows });
  };

  //We get devices in the store when component appear
  useEffect(() => {
    dispatch(fetchTaxiDevices());
  }, [dispatch]);

  //We want to display newly created devices without reloading the component
  useEffect(() => {
    if (devices[0]) fetchData();
  }, [devices]);

  return (
    <CardContent>
      <CursorHoverTr>
        <MDBDataTable
          striped
          entriesOptions={[10, 20, 50]}
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "of", "results"]}
          sortable
          searchLabel={"Search"}
          noRecordsFoundLabel={"There is no results"}
          paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
          paginationLabel={[
            <div style={{ cursor: "pointer" }}>Previous</div>,
            <div style={{ cursor: "pointer" }}>Next</div>,
          ]}
          info={true}
          entriesLabel={"Show results"}
          data={dataTable}
          sortRows={["usedAt"]}
        />
      </CursorHoverTr>
    </CardContent>
  );
};
