import { functionsFetch } from "../utils/functionsFetch";

export const createListing = async (listing) => {
  try {
    const res = await functionsFetch({
      method: "POST",
      url: `/taxi-modules/listings`,
      data: listing,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllListings = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/listings`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getListingById = async (id) => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-modules/listings/${id}`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const updateListingById = async (listing) => {
  try {
    const res = await functionsFetch({
      method: "PUT",
      url: `/taxi-modules/listings/${listing.id}`,
      data: listing,
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};

export const deleteListingById = async (id) => {
  try {
    const res = await functionsFetch({
      method: "DELETE",
      url: `/taxi-modules/listings/${id}`,
    });
  } catch (err) {
    alert(err);
    return Promise.reject(err);
  }
};
