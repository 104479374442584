import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDevices } from "../../modules/taxiDevices";

export const fetchTaxiDevices = createAsyncThunk(
  "taxiDevices/fetchTaxiDevices",
  async (arg, thunkAPI) => {
    try {
      const response = await getAllDevices();
      return response;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
