import { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { fetchTaxiModulesShop } from "../../../../../store/TaxiModulesShop/features";
import { Status } from "../../../../../components/Status";
import styled from "styled-components";
import ProductsModal from "../../components/ProductsModal";

const ContainerBtn = styled.div`
  width: 100%;
  .btn-add-product {
    display: flex;
    justify-content: end;
    margin: 12px 0;
  }
`;
const ImageProduct = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getStatus = (status) => {
  if (status) return "accepted";
  if (!status) return "refused";
};

export function ModuleProducts({ selectedModule }) {
  const dispatch = useDispatch();
  const { allProducts, loading } = useSelector(
    (state) => state.taxiModulesShop
  );

  const [dataTable, setDataTable] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sortedAllProducts, setSortedAllProducts] = useState(allProducts);

  const sortedData = useCallback(() => {
    const sortData = allProducts.slice().sort((a, b) => {
      return (
        (a.stock || 0) -
        (a.stockMinimal ?? 0) -
        ((b.stock ?? 0) - (b.stockMinimal ?? 0))
      );
    });
    return setSortedAllProducts(sortData);
  }, [allProducts]);

  const fetchData = () => {
    const columnNames = [
      "Id",
      "Image",
      "Name",
      "Sku",
      "Stock",
      "Min",
      "Comission",
      "Price",
      "Status",
      "Action",
    ];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));
    const rows = sortedAllProducts.map((product, id) => ({
      id: id + 1,
      image: product.image && (
        <ImageProduct>
          <img
            width={50}
            height={50}
            src={product.image}
            alt="products for sale"
          />
        </ImageProduct>
      ),
      name: product.name,
      sku: product.sku,
      price: product.price + " €",
      stock: product.stock || 0,
      min: product.stockMinimal || 0,
      comission: (product.comission || 0) + "%",
      status: (
        <Status status={getStatus(product.status)}>
          {product.status ? "Enabled" : "Disabled"}
        </Status>
      ),
      action: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setSelectedProduct(product);
          }}
          variant={"contained"}
        >
          Action
        </Button>
      ),
    }));
    setDataTable({ columns, rows });
  };

  useEffect(() => {
    if (selectedModule) {
      dispatch(fetchTaxiModulesShop(selectedModule?.widgetId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      fetchData();
      const timer = setTimeout(() => {
        const tableCard = document.getElementById("table");
        const rows = tableCard.querySelectorAll("tr");

        rows.forEach((row) => {
          const stock = row.children[4].textContent;
          const stockMinimal = row.children[5].textContent;

          if (Number(stock) <= Number(stockMinimal)) {
            row.style.backgroundColor = "#FC7878";
          } else {
            row.style.backgroundColor = "#FFF";
          }
        });
      }, 5);

      return () => clearTimeout(timer);
    }
  }, [loading, sortedAllProducts]);

  useEffect(() => {
    if (allProducts?.length) {
      sortedData();
    }
  }, [allProducts]);

  useEffect(() => {
    return () => {
      setDataTable({ columns: [], rows: [] });
    };
  }, [loading]);

  return (
    <>
      <ContainerBtn>
        <div className="btn-add-product">
          <Button onClick={() => setSelectedProduct(true)}>
            ADD A PRODUCT
          </Button>
        </div>
      </ContainerBtn>

      <MDBDataTable
        striped
        entriesOptions={[10, 20, 50]}
        entries={10}
        noBottomColumns
        infoLabel={["", "-", "of", "results"]}
        sortable
        searchLabel={"Search"}
        noRecordsFoundLabel={"There is no results"}
        paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
        paginationLabel={[
          <div style={{ cursor: "pointer" }}>Previous</div>,
          <div style={{ cursor: "pointer" }}>Next</div>,
        ]}
        info={true}
        entriesLabel={"Show results"}
        data={dataTable}
        sortRows={["usedAt"]}
        id="table"
      />
      <ProductsModal
        selectedModule={selectedModule}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        creation={!Boolean(selectedProduct?.id)}
      />
    </>
  );
}
