import { useCallback, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import CardContent from "@mui/material/CardContent";
import { format } from "date-fns";
import Button from "@mui/material/Button";
import { Modale } from "../../../../components/Modale";
import { deleteGroup } from "../../../../modules/taxiGroups";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxiGroups } from "../../../../store/TaxiGroups/features";

export const GroupsTable = () => {
  const [dataTable, setDataTable] = useState([]);
  const [groupOpen, setGroupOpen] = useState(null);
  const { taxiGroups: groups, isLoading } = useSelector(
    (state) => state.taxiGroups
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaxiGroups());
  }, [dispatch]);

  const fetchData = useCallback(() => {
    const columnNames = ["ID", "Name", "CreatedAt", "Action"];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (groups[0]) {
      groups.map((item, id) => {
        return rows.push({
          id,
          name: item.name,
          createdat: format(item.createdAt, "MM/dd/yyyy HH:mm"),
          whitelabel: item.whitelabel ? item.whitelabel : "No whitelabel",
          action: (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setGroupOpen(item);
              }}
              variant={"contained"}
            >
              Action
            </Button>
          ),
        });
      });
    }
    setDataTable({ columns, rows });
  }, [groups]);

  useEffect(() => {
    if (!isLoading) fetchData();
  }, [isLoading, fetchData]);

  return (
    <>
      <CardContent>
        <MDBDataTable
          striped
          entriesOptions={[10, 20, 50]}
          entries={10}
          noBottomColumns
          infoLabel={["", "-", "of", "results"]}
          sortable
          searchLabel={"Search"}
          noRecordsFoundLabel={"There is no results"}
          paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
          paginationLabel={[
            <div style={{ cursor: "pointer" }}>Previous</div>,
            <div style={{ cursor: "pointer" }}>Next</div>,
          ]}
          info={true}
          entriesLabel={"Show results"}
          data={dataTable}
          sortRows={["usedAt"]}
        />
      </CardContent>
      <Modale
        title={`Delete group «${groupOpen?.name}»`}
        isOpen={Boolean(groupOpen)}
        close={() => setGroupOpen(null)}
        actions={[
          {
            title: "Yes",
            click: async () => {
              await deleteGroup(groupOpen?.id);
              dispatch(fetchTaxiGroups());
              setGroupOpen(null);
            },
          },
        ]}
      />
    </>
  );
};
