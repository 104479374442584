import { createSlice } from "@reduxjs/toolkit";
import { fetchAllCampaigns } from "./features";

export const campaigns = createSlice({
    name: "campaigns",
    initialState: {
        campaigns: [],
        isLoading: true,
        isError: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCampaigns.fulfilled, (state, { payload }) => {
                state.campaigns = payload;
                state.isLoading = false;
                state.isError = false;
            })
            .addCase(fetchAllCampaigns.pending, (state, { payload }) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchAllCampaigns.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default campaigns.reducer;

export const campaignsSelector = (state) => state.campaigns;