import { createSlice } from "@reduxjs/toolkit";
import { fetchUsersCount, fetchUsersCountByWhitelabel } from "./features";

export const usersCount = createSlice({
  name: "usersCount",
  initialState: {
    usersCount: 0,
    usersCountByWhitelabel: 0,
  },
  reducers: {
    resetUsersWhitelabelCount: (state) => {
      state.usersCountByWhitelabel = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersCount.fulfilled, (state, { payload }) => {
      state.usersCount = payload;
    });

    builder.addCase(
      fetchUsersCountByWhitelabel.fulfilled,
      (state, { payload }) => {
        state.usersCountByWhitelabel = payload;
      }
    );
  },
});

export default usersCount.reducer;

export const usersCountSelector = (state) => state.usersCount;
export const usersCountByWhitelabelSelector = (state) =>
  state.usersCountByWhitelabel;

export const { resetUsersWhitelabelCount } = usersCount.actions;
