import { Button, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { KeyboardArrowLeft, KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import { ModuleTitle } from "./ModuleTitle";
import { ModulePreview } from "./ModulePreview";
import { ModuleBackground } from "./ModuleBackground";
import { ModuleDisplay } from "./ModuleDisplay";
import { ModuleIcon } from "./ModuleIcon";
import { storage } from "../../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: no-wrap;
  font-weight: bold;
`;

const Left = styled.div`
  width: 66.666%;
  border-radius: 12px;
  overflow: hidden;
`;

const Right = styled.div`
  width: 33.333%;
`;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

const TabContent = styled.div`
  display: ${(props) => (props.tab === props.currentTab ? "flex" : "none")};
  background-color: white;
  padding: 16px;
`;

const StyledTab = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: whitesmoke;
  header {
    &:hover {
      cursor: pointer;
    }
    padding: 16px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 2px lightgray;
  }
`;

const Tab = ({ tab, currentTab, setCurrentTab, children }) => {
  const handleTabClicked = () => {
    if (currentTab !== tab) setCurrentTab(tab);
    if (currentTab === tab) setCurrentTab("");
  };
  return (
    <StyledTab>
      <header onClick={() => handleTabClicked()}>
        <p>{tab}</p>
        {currentTab === tab ? <KeyboardArrowLeft /> : <KeyboardArrowDown />}
      </header>
      <TabContent tab={tab} currentTab={currentTab}>
        {children}
      </TabContent>
    </StyledTab>
  );
};

export function ModuleAppearance({
  selectedModule,
  setSelectedModule,
  handleSaveModule,
}) {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("Title");

  const handleSaveBackgroundImage = async () => {
    const backgroundImage = await handleAddImageToStorage(
      selectedModule.style.blob
    );
    const newSelectedModule = { ...selectedModule };
    delete newSelectedModule.style.blob;
    newSelectedModule.style.backgroundImage = backgroundImage;
    setSelectedModule(newSelectedModule);
  };

  const handleAddImageToStorage = async (blob) => {
    if (!blob) return null;
    const fileName = Date.now().toString();
    const metadata = {
      contentType: blob?.type || "image/jpeg",
    };

    const imageRef = ref(storage, "images/" + fileName);
    await uploadBytes(imageRef, blob, metadata);

    return getDownloadURL(imageRef).then((downloadURL) => {
      return downloadURL;
    });
  };
  return (
    <>
      <CardContent>
        <Container>
          <Left>
            <Tab
              tab="Title"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ModuleTitle
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
              />
            </Tab>
            <Tab
              tab="Background"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ModuleBackground
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
              />
            </Tab>
            <Tab
              tab="Icon"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ModuleIcon
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
              />
            </Tab>
            <Tab
              tab="Display"
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ModuleDisplay
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
              />
            </Tab>
          </Left>
          <Right>
            <ModulePreview selectedModule={selectedModule} />
          </Right>
        </Container>
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis?tab=modules")}>
            Back
          </Button>
          <Button
            onClick={async () => {
              if (selectedModule.style.blob) await handleSaveBackgroundImage();
              await handleSaveModule();
            }}
          >
            Save
          </Button>
        </Buttons>
      </CardContent>
    </>
  );
}
