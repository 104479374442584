import { useEffect, useState } from "react";
import { fetchWhitelabelList } from "../../../store/Whitelabel/WhitelabelList/features";
import { whitelabelListSelector } from "../../../store/Whitelabel/WhitelabelList";
import { useDispatch, useSelector } from "react-redux";
import { Global } from "../../../Panel/Global";
import { Card, CardHeader } from "@mui/material";
import BusinessCardTemplatesTable from "./components/BusinessCardTemplatesTable";
import { BusinessCardTemplatesModal } from "./components/BusinessCardTemplatesModal";

export default function BusinessCard() {
  const [businesCardTemplateModalOpen, setBusinesCardTemplateModalOpen] =
    useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { whiteLabelList: whitelabels } = useSelector(whitelabelListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWhitelabelList());
  }, []);

  return (
    <>
      <Card>
        <CardHeader title={"Business cards"} />
        <BusinessCardTemplatesModal
          selectedTemplate={selectedTemplate}
          close={() => {
            setBusinesCardTemplateModalOpen(false);
            setSelectedTemplate(null);
          }}
          isOpen={businesCardTemplateModalOpen}
          whitelabels={whitelabels}
        />

        <BusinessCardTemplatesTable
          setSelectedTemplate={setSelectedTemplate}
          setBusinesCardTemplateModalOpen={setBusinesCardTemplateModalOpen}
        />
      </Card>
    </>
  );
}
