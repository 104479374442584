import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAnalyticsCampaigns } from "../../modules/analyticsCampaigns";

export const fetchAllAnalyticsCampaigns = createAsyncThunk(
  "analyticsCampaigns/fetchAllAnalyticsCampaigns",
  async (arg, thunkAPI) => {
    try {
      const responseData = await getAllAnalyticsCampaigns();
      return responseData;
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
