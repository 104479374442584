import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiModules } from "./features";

export const taxiModules = createSlice({
  name: "taxiModules",
  initialState: {
    value: [],
    loading: true,
    error: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxiModules.fulfilled, (state, { payload }) => {
        state.value = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchTaxiModules.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchTaxiModules.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default taxiModules.reducer;

export const taxiModulesSelector = (state) => state.taxiModules;
