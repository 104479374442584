import { Slider } from "@mui/material";
import styled from "styled-components";
import { Picker } from "../../../../../components/ModalWhiteLabel/components/Picker";
import {
  ContainerInputColor,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { useState } from "react";
import { TablerIconsSelector } from "../../../../../components/TablerIconsSelector";

const StyledModuleIcon = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  label {
    width: calc(50% - 16px);
  }
  user-select: none;
`;

export function ModuleIcon({ selectedModule, setSelectedModule }) {
  const [viewColors, setViewColors] = useState([
    {
      name: "first",
      color: selectedModule.style?.iconColor || "#000000",
      position: "center",
      key: "color1",
    },
  ]);

  const handleChangeColor = (color, name) => {
    setViewColors(
      viewColors.map((viewColor) => {
        if (viewColor.name === name) {
          return {
            ...viewColor,
            color,
          };
        }
        return viewColor;
      })
    );
    setSelectedModule({
      ...selectedModule,
      style: {
        ...selectedModule.style,
        iconColor: color,
      },
    });
  };

  return (
    <StyledModuleIcon>
      <LabelInput htmlFor="">
        Icon
        <TablerIconsSelector
          icon={selectedModule.style?.icon || ""}
          setIcon={(icon) =>
            setSelectedModule({
              ...selectedModule,
              style: { ...selectedModule.style, icon },
            })
          }
          size={selectedModule.style?.iconSize || 32}
          color={selectedModule.style?.iconColor || "#000000"}
          stroke={selectedModule.style?.iconStroke || 1}
        />
      </LabelInput>
      <LabelInput htmlFor="">
        <ContainerInputColor color>
          Color
          <div className="wrapper-collor-btn">
            <Picker
              values={{
                iconColor: selectedModule.style?.iconColor || "#000000",
              }}
              onChange={(color, key) =>
                setSelectedModule({
                  ...selectedModule,
                  style: {
                    ...selectedModule.style,
                    iconColor: color,
                  },
                })
              }
              options={{
                title: "Icon Color",
                position: "center",
                key: "iconColor",
              }}
            />
          </div>
        </ContainerInputColor>
      </LabelInput>
      <LabelInput htmlFor="size">
        Size
        <Slider
          name="size"
          min={22}
          max={72}
          step={2}
          value={selectedModule.style?.iconSize || 32}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: {
                ...selectedModule.style,
                iconSize: e.target.value,
              },
            })
          }
        />
      </LabelInput>
      <LabelInput htmlFor="stroke">
        Stroke
        <Slider
          name="size"
          min={0.5}
          max={2}
          step={0.1}
          value={selectedModule.style?.iconStroke || 1}
          onChange={(e) =>
            setSelectedModule({
              ...selectedModule,
              style: {
                ...selectedModule.style,
                iconStroke: e.target.value,
              },
            })
          }
        />
      </LabelInput>
    </StyledModuleIcon>
  );
}
