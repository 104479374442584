import React, { useState } from "react";
import { Modale } from "../Modale";
import { addRessourceByUid } from "../../modules/resources";
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import styled from "styled-components";
import types from "./types";
import Resource from "./Resource";

const defaultResourceToAdd = { type: types[0], value: 1 };

const AddButtonWrapper = styled.div`
  margin-top: 16px;
`;

export const ModalAddResources = ({ uid, isOpen, close }) => {
  const [resourcesToAdd, setResourcesToAdd] = useState([defaultResourceToAdd]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await Promise.all(
      resourcesToAdd.map(({ type, value }) =>
        addRessourceByUid(uid, type, value)
      )
    );
    setLoading(false);
    close();
  };

  const handleAddResource = () => {
    setResourcesToAdd([...resourcesToAdd, defaultResourceToAdd]);
  };

  const handleDeleteResource = (index) => {
    setResourcesToAdd(resourcesToAdd.filter((_, i) => i !== index));
  };
  return (
    <Modale
      isOpen={isOpen}
      close={close}
      title="Ajouter des ressources"
      disabled={loading}
      actions={[
        {
          title: "Confirmer",
          click: async () => {
            await handleSubmit();
            close();
          },
        },
      ]}
    >
      {resourcesToAdd.map((resource, index) => {
        return (
          <Resource
            key={index}
            index={index}
            type={resource.type}
            value={resource.value}
            onChange={(change) =>
              setResourcesToAdd(
                resourcesToAdd.map((resource, i) =>
                  i === index ? { ...resource, ...change } : resource
                )
              )
            }
            onDelete={() => handleDeleteResource(index)}
          />
        );
      })}
      <AddButtonWrapper>
        <Button onClick={handleAddResource}>
          <AddOutlined />
        </Button>
      </AddButtonWrapper>
    </Modale>
  );
};

export default ModalAddResources;
