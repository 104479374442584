import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const StyledModuleSettings = styled.div``;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

export function ModuleSettings({
  selectedModule,
  setSelectedModule,
  handleSaveModule,
}) {
  const navigate = useNavigate();

  return (
    <StyledModuleSettings>
      {(selectedModule?.widgetId === "l5YDfst7wXb1pc2nzucI" ||
        selectedModule?.widgetId === "qnbmHbFeRjaQ7zSNc8N9") && (
        <LabelInput htmlFor="account">
          Account Id
          <Input
            type="account"
            id="account"
            placeholder="Enter an account id"
            value={selectedModule.accountUid || ""}
            onChange={(e) =>
              setSelectedModule({
                ...selectedModule,
                accountUid: e.target.value,
              })
            }
            name="account"
          />
        </LabelInput>
      )}
      <Buttons>
        <Button onClick={() => navigate("/dashboard/taxis?tab=modules")}>
          Back
        </Button>
        <Button onClick={() => handleSaveModule()}>Save</Button>
      </Buttons>
    </StyledModuleSettings>
  );
}
