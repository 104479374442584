import * as React from "react";
import { useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { MDBDataTable } from "mdbreact";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { programFidelityListSelector } from "../../../store/ProgramFidelityList/index";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import { formatDate } from "../../../utils/date";
import { Modale } from "../../../components/Modale";
import { fetchAllProgramFidelity } from "../../../store/ProgramFidelityList/features";
import {
  acceptProgramFidelityUsers,
  refuseProgramFidelityUsers,
} from "../../../modules/programFidelityRequests";
import { Status } from "../../../components/Status";
import styled from "styled-components";

const BannerPreview = styled.img`
  height: 50px;
  width: 150px;
  object-fit: cover;
`;

const useStyle = (theme) => ({
  cardHeader: {
    textDecorationColor: "whiter",
    backgroundColor: "#96CED5",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(6, 210, 235, .3)",
  },
});

const getStatus = (status) => {
  if (status === null || status === undefined) return "pending";
  if (status) return "accepted";
  if (!status) return "refused";
};

export default function ProgramFidelityRequestsList() {
  const { programFidelityList, isLoading } = useSelector(
    programFidelityListSelector
  );
  const [dataTable, setDataTable] = useState([]);
  const [selectedProgram, setselectedProgram] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const classes = useStyle();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) fetchData();
  }, [isLoading]);

  useEffect(() => {
    if (refetch) {
      dispatch(fetchAllProgramFidelity());
      setRefetch(false);
      setselectedProgram(null);
    }
  }, [refetch, dispatch]);

  const fetchData = () => {
    const columnNames = [
      "Bannière",
      "Status",
      "Société",
      "Points_total",
      "Promotion",
      "Adresse",
      "Action",
    ];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    let rows = [];
    if (programFidelityList && programFidelityList.length > 0) {
      programFidelityList.map((item) => {
        rows.push({
          bannière: item.url ? <BannerPreview src={item.url} /> : "",
          status: (
            <Status status={getStatus(item.isActive)}>
              {getStatus(item.isActive)}
            </Status>
          ),
          createdAt: formatDate(item.createdAt),
          société: item.companyName,
          points_total: item.pointsTotal,
          promotion: <p>{item.promotion}</p>,
          adresse: item.adress,
          action: (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setselectedProgram(item);
              }}
              variant={"contained"}
            >
              Action
            </Button>
          ),
        });
      });
    }
    setDataTable({ columns, rows });
  };

  if (isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardHeader className={classes.cardHeader} title={"Requests"} />
        <CardContent>
          <MDBDataTable
            striped
            entriesOptions={[10, 20, 50]}
            entries={10}
            noBottomColumns
            infoLabel={["", "-", "sur", "résultats"]}
            sortable
            searchLabel={"Recherche"}
            noRecordsFoundLabel={"aucun enregistrement correspondant trouvé"}
            paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
            paginationLabel={[
              <div style={{ cursor: "pointer" }}>Précédent</div>,
              <div style={{ cursor: "pointer" }}>Suivant</div>,
            ]}
            info={true}
            entriesLabel={"Afficher les résultats"}
            data={dataTable}
          />
        </CardContent>
      </Card>
      <Modale
        title="Demande programme de fidélité."
        content="Voulez-vous accepter ce programme de fidélité ?"
        isOpen={selectedProgram !== null}
        close={() => setselectedProgram(null)}
        actions={[
          {
            title: "Non",
            click: () =>
              refuseProgramFidelityUsers(selectedProgram, () =>
                setRefetch(true)
              ),
          },
          {
            title: "Oui",
            click: () =>
              acceptProgramFidelityUsers(selectedProgram, () =>
                setRefetch(true)
              ),
          },
        ]}
      />
    </>
  );
}
