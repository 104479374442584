import React, { useState, useEffect } from "react";
import { Modale } from "../../../../components/Modale";
import { useDispatch } from "react-redux";
import { WhitelabelSelection } from "../../../../components/WhitelabelSelection";
import { updateBusinessCardTemplatesById } from "../../../../modules/businessCardTemplates";
import { fetchBusinessCardTemplates } from "../../../../store/BusinessCardTemplates/features";

export const BusinessCardTemplatesModal = ({
  close,
  selectedTemplate,
  whitelabels,
  ...props
}) => {
  //
  const [businessCardTemplate, setBusinessCardTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onClose = () => {
    setLoading(false);
    close();
  };

  const handleUpdateBusinessCardtemplate = async () => {
    try {
      setLoading(true);
      await updateBusinessCardTemplatesById({
        id: businessCardTemplate.id,
        data: {
          whitelabels: [...businessCardTemplate.whitelabels],
        },
      });
      dispatch(fetchBusinessCardTemplates());
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (loading) return null;
    return handleUpdateBusinessCardtemplate();
  };

  useEffect(() => {
    if (selectedTemplate) {
      setBusinessCardTemplate(selectedTemplate);
    }
  }, [selectedTemplate]);

  if (!selectedTemplate || !businessCardTemplate) return null;

  return (
    <Modale
      title={"Edit Business card templates"}
      close={onClose}
      actions={[
        {
          title: "Submit",
          click: onSubmit,
        },
      ]}
      disabled={loading}
      {...props}
    >
      <WhitelabelSelection
        multiple
        value={businessCardTemplate.whitelabels || []}
        onChange={(newValue) =>
          setBusinessCardTemplate({
            ...businessCardTemplate,
            whitelabels: newValue,
          })
        }
      />
    </Modale>
  );
};
