import { useState, useEffect } from "react";
import { Modale } from "../../../../components/Modale";
import { EditTabsSaleOnBoard } from "./EditTabsSaleOnBoard";
import {
  createProductTaxi,
  updateProductInTaxi,
  deleteProductInTaxiById,
} from "../../../../modules/taxiDevicesShop";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchTaxideviceShop } from "../../../../store/TaxiDevicesShop/features";
import { useSelector } from "react-redux";
import { updateProductsModulesShop } from "../../../../modules/taxiModulesShop";
import { fetchTaxiModules } from "../../../../store/TaxiModules/features";
import { fetchTaxiModulesShop } from "../../../../store/TaxiModulesShop/features";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

export const ModalSaleOnBoard = ({
  setSelectedProduct,
  selectedProduct,
  selectedDevice,
  creation,
}) => {
  const dispatch = useDispatch();
  const [openDeleteModale, setOpenDeleteModale] = useState(false);
  const [values, setValues] = useState({
    deviceId: selectedDevice?.id,
    productId: "",
    stock: 0,
    stockMinimal: 0,
  });
  const allProducts = useSelector((state) => state.taxiModulesShop.allProducts);
  const modules = useSelector((state) => state.taxiModules.value);
  const [initialStock, setInitialStock] = useState(0);

  const handleChange = (target) => {
    const { value, name } = target;
    setValues({ ...values, [name]: value });
  };

  const checkCanUpdateStock = () => {
    const quantity = (values.stock || 0) - initialStock;
    if (quantity < 0) {
      toast.error(`You are not allowed to remove products here.`);
      return false;
    }
    if (checkModuleShopHaveEnoughtStock(quantity) === false) return false;
    return true;
  };

  const checkModuleShopHaveEnoughtStock = (quantity) => {
    const moduleShopProduct = allProducts.find(
      (product) => product.id === values.productId
    );
    if (parseInt(moduleShopProduct.stock) < parseInt(quantity)) {
      toast.error(
        `You want to get ${quantity} «${moduleShopProduct.name}» but vendor only have ${moduleShopProduct.stock}.`
      );
      return false;
    }
    return true;
  };

  const onCreateProduct = async () => {
    if (!values?.productId?.length)
      return toast.error("You should select a product");
    if (parseInt(values.stock) === 0)
      return toast.error("You should add stock");
    if (parseInt(values.stockMinimal) === 0)
      return toast.error("You should add minimal stock");
    if (checkModuleShopHaveEnoughtStock(parseInt(values.stock)) === false)
      return;
    await createProductTaxi(selectedDevice.id, {
      shopId: selectedDevice?.shopId,
      ...values,
    });

    await updateProductsModulesShop(selectedDevice.shopId, {
      id: values.productId,
      stock:
        parseInt(
          allProducts.find((product) => product.id === values.productId).stock
        ) - parseInt(values.stock),
    });

    dispatch(fetchTaxideviceShop(selectedDevice));
    dispatch(fetchTaxiModulesShop(selectedDevice.shopId));
    toast.success("Product created");
    setSelectedProduct(null);
  };

  const onUpdateProduct = async () => {
    if (checkCanUpdateStock()) {
      try {
        if (!values.name.length) return toast.error("Name can't be empty");

        const dataRequest = {
          id: selectedProduct?.id,
          stock: values.stock,
          stockMinimal: values.stockMinimal,
        };

        await updateProductInTaxi(selectedDevice.id, dataRequest);

        await updateProductsModulesShop(selectedDevice.shopId, {
          id: values.productId,
          stock:
            parseInt(
              allProducts.find((product) => product.id === values.productId)
                .stock
            ) -
            (parseInt(values.stock) - initialStock),
        });
        dispatch(fetchTaxideviceShop(selectedDevice));
        dispatch(selectedDevice.shopId);
        toast.success("Updated product");
        setSelectedProduct(null);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDeleteProduct = async () => {
    await deleteProductInTaxiById(selectedDevice.id, selectedProduct.id);
    dispatch(fetchTaxideviceShop(selectedDevice));
    toast.success("Delete product");
    setOpenDeleteModale(false);
    setSelectedProduct(null);
  };

  const handleSubmit = () => {
    if (creation) {
      onCreateProduct();
    } else {
      onUpdateProduct();
    }
  };
  useEffect(() => {
    if (selectedProduct) {
      setValues({
        deviceId: selectedDevice?.id,
        productId: selectedProduct?.productId || "",
        name: selectedProduct?.name || "",
        stock: selectedProduct?.stock || "",
        stockMinimal: selectedProduct?.stockMinimal || "",
      });
      setInitialStock(selectedProduct.stock || 0);
    }
  }, [selectedProduct]);

  useEffect(() => {
    dispatch(fetchTaxiModules());
  }, []);

  useEffect(() => {
    dispatch(fetchTaxiModulesShop(selectedDevice.shopId));
  }, [modules, dispatch, selectedDevice.shopId]);

  return (
    <>
      <Modale
        title={creation ? "Add product" : "Product details"}
        isOpen={selectedProduct !== null}
        close={() => setSelectedProduct(null)}
        actions={[
          {
            title: creation ? "Create" : "Update",
            click: () => handleSubmit(),
          },
        ]}
      >
        <EditTabsSaleOnBoard
          setValues={setValues}
          values={values}
          handleChange={handleChange}
          creation={creation}
          onOpenModaleDelete={() => setOpenDeleteModale(true)}
          allProducts={allProducts}
        />
      </Modale>
      {openDeleteModale && !creation && (
        <ConfirmationModal
          title="Delete product"
          content={`Do you realy want to delete «${values.name}» ?`}
          isOpen={openDeleteModale}
          close={() => setOpenDeleteModale(false)}
          onConfirmation={() => handleDeleteProduct()}
        />
      )}
    </>
  );
};
