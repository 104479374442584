import * as React from "react";
import { useEffect, useState } from "react";
import CardHeader from "@mui/material/CardHeader";
import { MDBDataTable } from "mdbreact";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { ordersListSelector } from "../../../store/Orders/OrderList";
import { fetchOrdersList } from "../../../store/Orders/OrderList/features";
import Button from "@mui/material/Button";
import OrderDetails from "./orderDetails";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { fetchOrdersProcessed } from "../../../store/Orders/OrderProcessed/features";
import { orderProcessedSelector } from "../../../store/Orders/OrderProcessed";
import { whitelabelListSelector } from "../../../store/Whitelabel/WhitelabelList";

const useStyle = (theme) => ({
  cardHeader: {
    textDecorationColor: "whiter",
    backgroundColor: "#96CED5",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(6, 210, 235, .3)",
  },
});
export default function OrdersList() {
  const { selectedWhiteLabel } = useSelector(whitelabelListSelector);
  const { ordersList, isLoading } = useSelector(ordersListSelector);
  const { orderProcessedList, isLoadingOrderProcessed } = useSelector(
    orderProcessedSelector
  );
  const [dataTable, setDataTable] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [allOrdersActualMonth, setAllOrdersActualMonth] = useState([]);

  useEffect(() => {
    dispatch(fetchOrdersList());
    dispatch(fetchOrdersProcessed());
  }, []);

  const totalAmountOrders = (arr) => {
    const total = arr
      .filter((x) => {
        if (!selectedWhiteLabel || selectedWhiteLabel === "All whitelabels") {
          return true;
        }
        if (!x.whitelabel) {
          return false;
        }
        return selectedWhiteLabel.toLowerCase() === x.whitelabel.toLowerCase();
      })
      .reduce((acc, curr) => acc + parseFloat(curr.amount), 0);
    return total.toFixed(2);
  };

  useEffect(() => {
    fetchData();
  }, [selectedWhiteLabel]);

  useEffect(() => {
    let actualMonth = new Date().getMonth();
    let allOrdersThisMonth = ordersList
      .filter((x) => {
        if (!selectedWhiteLabel || selectedWhiteLabel === "All whitelabels") {
          return true;
        }
        if (!x.whitelabel) {
          return false;
        }
        return selectedWhiteLabel.toLowerCase() === x.whitelabel.toLowerCase();
      })
      .filter((el) => {
        var t = new Date(1970, 0, 1); // Epoch
        t.setSeconds(el.created_at._seconds);
        return t.getMonth() === actualMonth;
      });
    setAllOrdersActualMonth(allOrdersThisMonth);
  }, [ordersList, selectedWhiteLabel]);
  const classes = useStyle();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading || !isLoadingOrderProcessed) fetchData();
  }, [isLoading, isLoadingOrderProcessed]);

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(date._seconds);
    var d = new Date(t),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const fetchData = async () => {
    let columns = [
      {
        label: <div style={{ cursor: "pointer", fontWeight: "bold" }}>ID</div>,
        field: "id",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Status</div>
        ),
        field: "status",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Price</div>
        ),
        field: "price",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Ship cost</div>
        ),
        field: "ship_cost",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Date</div>
        ),
        field: "date",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Quantity</div>
        ),
        field: "quantity",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>
            Whitelabel
          </div>
        ),
        field: "whitelabel",
      },
      {
        label: (
          <div style={{ cursor: "pointer", fontWeight: "bold" }}>Action</div>
        ),
        field: "action",
      },
    ];
    let rows = [];
    if (ordersList && ordersList.length > 0) {
      ordersList
        .filter((x) => {
          if (!selectedWhiteLabel || selectedWhiteLabel === "All whitelabels") {
            return true;
          }
          if (!x.whitelabel) {
            return false;
          }
          return (
            selectedWhiteLabel.toLowerCase() === x.whitelabel.toLowerCase()
          );
        })
        .map((item) =>
          rows.push({
            id: item.orderId,
            status: orderProcessedList
              .map((x) => x.orderId)
              .includes(item.orderId) ? (
              <small
                style={{
                  background: "#2cd091",
                  color: "#FFF",
                  padding: 10,
                  borderRadius: 15,
                }}
              >
                Completed
              </small>
            ) : (
              <small
                style={{
                  background: "#f1f1fa",
                  color: "#000",
                  padding: 10,
                  borderRadius: 15,
                }}
              >
                Waiting
              </small>
            ),
            price: (item.amount || "0.00") + "€",
            ship_cost: (item.shipping_cost || "0.00") + "€",
            date: formatDate(item.created_at),
            whitelabel: item.whitelabel,
            quantity: item.items.length,
            action: (
              <Button
                varian={"contained"}
                onClick={() => setSelectedOrder(item)}
              >
                Voir
              </Button>
            ),
          })
        );
    }
    setDataTable({ columns, rows });
  };

  return (
    <>
      <Card>
        <CardHeader className={classes.cardHeader} title={"Orders"} />
        <CardContent>
          <Grid container spacing={3} mb={4}>
            <Grid item xs>
              <Card sx={{ height: 100 }}>
                <CardContent>
                  <Typography fontWeight="bold">CA - ACTUAL MONTH</Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {totalAmountOrders(allOrdersActualMonth)}€
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ height: 100 }}>
                <CardContent>
                  <Typography fontWeight="bold">
                    Nb Orders - ACTUAL MONTH
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {allOrdersActualMonth.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ height: 100 }}>
                <CardContent>
                  <Typography fontWeight="bold">CA - TOTAL</Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {totalAmountOrders(ordersList)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs>
              <Card sx={{ height: 100 }}>
                <CardContent>
                  <Typography fontWeight="bold">Nb Orders - TOTAL</Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {ordersList.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <MDBDataTable
            striped
            entriesOptions={[10, 20, 50]}
            entries={10}
            noBottomColumns
            infoLabel={["", "-", "sur", "résultats"]}
            sortable
            searching={false}
            paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
            paginationLabel={[
              <div style={{ cursor: "pointer" }}>Précédent</div>,
              <div style={{ cursor: "pointer" }}>Suivant</div>,
            ]}
            searchLabel={"Recherche"}
            noRecordsFoundLabel={"aucun enregistrement correspondant trouvé"}
            info={true}
            entriesLabel={"Afficher les résultats"}
            data={dataTable}
          />
        </CardContent>
      </Card>

      {selectedOrder && (
        <Dialog open={true} onClose={() => setSelectedOrder(null)}>
          <DialogTitle>Order details</DialogTitle>
          <DialogContent>
            <OrderDetails selectedOrder={selectedOrder} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
