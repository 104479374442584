import React from "react";
import styled from "styled-components";
import MSlider from "@mui/material/Slider";

const WrapperSlider = styled.div`
  display: flex;
  gap: 24px;

  span {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .MuiSlider-colorPrimary {
    color: ${({ theme }) => theme.color2};
  }

  .MuiSlider-thumbCol {
    width: 100%;
  }

  @media screen and (max-width: 455px) {
    .MuiSlider-rail {
      width: 100%;
    }
    .MuiSlider-thumbCol {
      width: 100%;
    }
  }
`;

export const Slider = ({
  unit = "s",
  wrapperStyle = {},
  suffix,
  noValuePossible,
  ...props
}) => {
  const formatValue = () => `${props.value} ${unit}`;
  const getLabel = () => {
    return `${suffix || ""} ${
      props.value || !noValuePossible ? formatValue() : "All"
    }`;
  };
  return (
    <WrapperSlider style={wrapperStyle} className="slider">
      <span className="label">{getLabel()}</span>
      <MSlider {...props} />
    </WrapperSlider>
  );
};
