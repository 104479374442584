import { useState } from "react";
import { PickerOverlay } from "filestack-react";

const Filestack = ({
  setValues,
  urlIcon,
  typeVideo,
  size = 10,
  accept = "image/*",
  aspectRatio,
}) => {
  const [openFileStack, setOpenFileStack] = useState(false);

  const onChangeImage = async (res, type) => {
    if (res.filesUploaded[0]?.url !== null) {
      var obj = res.filesUploaded[0];
      await fetch(obj.url)
        .then((r) => r.arrayBuffer())
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: typeVideo ? typeVideo : "image/jpeg",
          });
          setValues(res.filesUploaded[0].url, blob);
        });
    }
  };

  return (
    <>
      {openFileStack && (
        <PickerOverlay
          apikey="AkwiaSgsTbuLq3zgwxKlgz"
          onSuccess={(res) => onChangeImage(res)}
          pickerOptions={{
            accept,
            maxSize: 1048576 * size,
            maxFiles: 1,

            transformations: {
              crop: {
                aspectRatio,
                force: true,
              },
              circle: false,
            },
            onClose: () => {
              setOpenFileStack(false);
            },
          }}
        />
      )}
      <img
        style={{ cursor: "pointer" }}
        onClick={() => setOpenFileStack(true)}
        src={urlIcon}
        alt="icon-add"
      />
    </>
  );
};
export default Filestack;
