import { functionsFetch } from "../utils/functionsFetch";

export const createGroup = async group => {
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/taxi-groups/create/${group}`,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const deleteGroup = async group => {
  try {
    const response = await functionsFetch({
      method: "DELETE",
      url: `/taxi-groups/delete/${group}`,
    });
    const data = await response.data;
    return data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};

export const getAllGroups = async () => {
  try {
    const res = await functionsFetch({
      method: "GET",
      url: `/taxi-groups`,
    });
    return res.data;
  } catch (err) {
    alert(err.response.data.error);
    return Promise.reject(err.response.data);
  }
};
