import { functionsFetch } from "../utils/functionsFetch";

export const addRessourceByUid = async (uid, type, value, quest = []) => {
  const queryParams = quest.length ? `?quest=${quest.join("-")}` : "";
  try {
    const response = await functionsFetch({
      method: "POST",
      url: `/resource-gains/${uid}${queryParams}`,
      data: { type, value },
    });
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
  } catch (error) {
    alert(error.response.data.error);
    return Promise.reject(error.response.data);
  }
};
