import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Button, CardContent } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllListings } from "../../../../../store/TaxiModuleListing/features";
import { useEffect } from "react";
import { format } from "date-fns";
import { ModuleListingModal } from "./ModuleListingModal";

const StyledModuleListings = styled.div``;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
  justify-content: flex-end;
`;

export function ModuleListings({}) {
  const [dataTable, setDataTable] = useState([]);
  const listings = useSelector((state) => state.moduleListing.listings);
  const dispatch = useDispatch();
  const [isModuleListingModalOpen, setIsModuleListingModalOpen] =
    useState(false);
  const [selectedListing, setSelectedListing] = useState(null);

  const fetchDataTable = () => {
    const columnNames = ["Id", "Title", "Created_At", "Action"];
    const columns = columnNames.map((item) => ({
      label: (
        <div style={{ cursor: "pointer", fontWeight: "bold" }}>
          {item.replace("_", " ")}
        </div>
      ),
      field: item.toLowerCase(),
    }));

    const rows = [];
    if (listings[0]) {
      let listingsToDisplay = listings;
      listingsToDisplay.map((listing, id) => {
        return rows.push({
          id: id + 1,
          title: listing.title,
          created_at: format(listing.createdAt, "MM/dd/yyyy HH:mm"),
          action: (
            <Button
              onClick={() => {
                setSelectedListing(listing);
                setIsModuleListingModalOpen(true);
              }}
            >
              ACTION
            </Button>
          ),
        });
      });
    }
    setDataTable({ columns, rows });
  };

  useEffect(() => {
    dispatch(fetchAllListings());
  }, [dispatch]);

  useEffect(() => {
    fetchDataTable();
  }, [listings]);

  return (
    <>
      <StyledModuleListings>
        <ModuleListingModal
          isOpen={isModuleListingModalOpen}
          setIsOpen={setIsModuleListingModalOpen}
          selectedListing={selectedListing}
          setSelectedListing={setSelectedListing}
        />
        <CardContent>
          <MDBDataTable
            striped
            entriesOptions={[10, 20, 50]}
            entries={10}
            noBottomColumns
            infoLabel={["", "-", "of", "results"]}
            sortable
            searchLabel={"Search"}
            noRecordsFoundLabel={"There is no results"}
            paging={dataTable && dataTable.rows && dataTable.rows.length > 0}
            paginationLabel={[
              <div style={{ cursor: "pointer" }}>Previous</div>,
              <div style={{ cursor: "pointer" }}>Next</div>,
            ]}
            info={true}
            entriesLabel={"Show results"}
            data={dataTable}
            sortRows={["usedAt"]}
          />
          <Buttons>
            <Button onClick={() => setIsModuleListingModalOpen(true)}>
              ADD A LISTING
            </Button>
          </Buttons>
        </CardContent>
      </StyledModuleListings>
    </>
  );
}
