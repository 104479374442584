import { useState, useEffect } from "react";
import { Modale } from "../../../../components/Modale";
import { ContainerAllEdition } from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import EditionTabsProducts from "./EditionTabsProducts";
import { fetchTaxiModulesShop } from "../../../../store/TaxiModulesShop/features";
import { useDispatch } from "react-redux";
import { storage } from "../../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  createProductsModulesShop,
  updateProductsModulesShop,
  deleteProductById,
} from "../../../../modules/taxiModulesShop";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

export default function ModalProducts({
  selectedModule,
  creation,
  selectedProduct,
  setSelectedProduct,
}) {
  const [openDeleteModale, setOpenDeleteModale] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    image: selectedProduct?.image || "",
    name: selectedProduct?.name || "",
    sku: selectedProduct?.sku || "",
    price: selectedProduct?.price || "",
    status: selectedProduct?.status || false,
    description: selectedProduct?.description || "",
  });

  const [blob, setBlob] = useState(null);

  const handleChange = (target) => {
    const { value, name } = target;
    setValues({ ...values, [name]: value });
  };

  const handleCheck = (target, reverse) => {
    const { checked, name } = target;
    setValues({ ...values, [name]: reverse ? !checked : checked });
  };

  const handleAddImageToStorage = async (blob) => {
    if (!blob) return null;
    const fileName = Date.now().toString();
    const metadata = {
      contentType: blob?.type || "image/jpeg",
    };

    const imageRef = ref(storage, "images/" + fileName);
    await uploadBytes(imageRef, blob, metadata);

    return getDownloadURL(imageRef).then((downloadURL) => {
      return downloadURL;
    });
  };

  const onCreateProduct = async () => {
    try {
      if (!values?.name?.length)
        return alert("Veuillez remplir le champ name.");
      if (!values?.price?.length)
        return alert("Veuillez remplir le champ price.");
      if (!values?.sku.length) return alert("Veuillez remplir le champ SKU.");
      const image = (await handleAddImageToStorage(blob)) || values.image;
      if (!image) return alert("Veuillez remplir le logos.");

      const dataRequest = {
        ...values,
        image,
      };

      await createProductsModulesShop(selectedModule.widgetId, dataRequest);
      dispatch(fetchTaxiModulesShop(selectedModule?.widgetId));
      setSelectedProduct(null);
    } catch (error) {
      console.log(error);
    }
  };
  const onUpdateProduct = async () => {
    try {
      if (!values.name.length) return alert("Veuillez remplir le champ name.");

      const dataRequest = {
        ...values,
        id: selectedProduct?.id,
        image: (await handleAddImageToStorage(blob)) || values.image,
      };

      await updateProductsModulesShop(selectedModule.widgetId, dataRequest);
      dispatch(fetchTaxiModulesShop(selectedModule?.widgetId));
      setSelectedProduct(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    if (creation) {
      onCreateProduct();
    } else {
      onUpdateProduct();
    }
  };

  const handleDeleteProduct = async () => {
    await deleteProductById(selectedModule.widgetId, selectedProduct.id);
    dispatch(fetchTaxiModulesShop(selectedModule?.widgetId));
    setOpenDeleteModale(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    setValues((values) => ({
      ...values,
      image: blob ? URL.createObjectURL(blob) : values.image,
    }));
  }, [blob]);

  useEffect(() => {
    if (selectedProduct) {
      setValues({
        image: selectedProduct?.image || "",
        name: selectedProduct?.name || "",
        sku: selectedProduct?.sku || "",
        price: selectedProduct?.price || "",
        status: selectedProduct?.status || false,
        description: selectedProduct?.description || "",
        stock: selectedProduct?.stock || 0,
        stockMinimal: selectedProduct?.stockMinimal || 0,
        comission: selectedProduct?.comission || 0,
      });
    }
  }, [selectedProduct]);

  return (
    <>
      <Modale
        title={creation ? "Create a product" : "Product details"}
        isOpen={selectedProduct !== null}
        close={() => setSelectedProduct(null)}
        actions={[
          {
            title: "Sauvegarder",
            click: () => handleSubmit(),
          },
        ]}
      >
        <ContainerAllEdition>
          <EditionTabsProducts
            values={values}
            handleChange={handleChange}
            handleCheck={handleCheck}
            onOpenModaleDelete={() => setOpenDeleteModale(true)}
            creation={creation}
            setBlob={setBlob}
          />
        </ContainerAllEdition>
      </Modale>
      {openDeleteModale && !creation && (
        <ConfirmationModal
          title="Delete product"
          content={`Are you sure you want to delete «${values.name}» ?`}
          isOpen={openDeleteModale}
          close={() => setOpenDeleteModale(false)}
          onConfirmation={() => handleDeleteProduct()}
        />
      )}
    </>
  );
}
