import React from "react";
import {
  LabelInput,
  Input,
} from "../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { CheckBox } from "../../../../components/CheckBox";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ImageUpload from "../../../../components/ImageUpload";

export default function EditionTabsProducts({
  values,
  handleChange,
  handleCheck,
  setBlob,
  onOpenModaleDelete,
  creation,
}) {
  return (
    <>
      <LabelInput htmlFor="name">
        Name
        <Input
          type="text"
          id="name"
          placeholder="name"
          value={values.name}
          onChange={(e) => handleChange(e.target)}
          name="name"
        />
      </LabelInput>
      <LabelInput htmlFor="sku">
        Sku
        <Input
          id="sku"
          placeholder="sku"
          value={values.sku}
          onChange={(e) => handleChange(e.target)}
          name="sku"
        />
      </LabelInput>
      <LabelInput htmlFor="price">
        Price
        <Input
          type="number"
          id="price"
          placeholder="price"
          value={values.price}
          onChange={(e) => handleChange(e.target)}
          name="price"
        />
      </LabelInput>
      <LabelInput style={{ minWidth: "300px" }} htmlFor="stock">
        Stock
        <Input
          type="number"
          id="stock"
          placeholder="stock"
          value={values.stock}
          onChange={(e) => handleChange(e.target)}
          name="stock"
        />
      </LabelInput>
      <LabelInput htmlFor="stockMinimal">
        Stock min
        <Input
          type="number"
          id="stockMinimal"
          placeholder="stock minimal"
          value={values.stockMinimal}
          onChange={(e) => handleChange(e.target)}
          name="stockMinimal"
        />
      </LabelInput>
      <LabelInput htmlFor="stockMinimal">
        Comission taxi
        <Input
          type="number"
          id="comission"
          placeholder="comission"
          min={0}
          max={100}
          value={values.comission}
          onChange={(e) => handleChange(e.target)}
          name="comission"
        />
      </LabelInput>
      <CheckBox
        title="Activer le produit"
        id="status"
        checked={values.status}
        onChange={(e) => handleCheck(e.target)}
        name="status"
      />
      {/* <ContainerUploadLogo>
        <LabelInput htmlFor="">Image</LabelInput>
        <div className="wrapper-logosmall">
          <span>Image product | (480px / 480px) </span>
          <div className="wrapper_img">
            {values.image && <img alt="product" src={values.image} />}
          </div>
          <div className="upload">
            <input type="file" id="hiddenfile" style={{ display: "none" }} />
            <ButtonFile
              type="button"
              onClick={() => setOpenFileStackSmall(true)}
            >
              <FileUploadOutlinedIcon />
              Change
            </ButtonFile>
          </div>
        </div>
      </ContainerUploadLogo> */}
      <ImageUpload
        title="Image"
        initialPreview={values.image}
        setBlob={setBlob}
        ratio={1}
        width="480"
        height="480"
        size="50%"
      />
      <LabelInput htmlFor="description">
        Description
        <textarea
          className="description-product"
          value={values.description}
          name="description"
          onChange={(e) => handleChange(e.target)}
        />
      </LabelInput>
      {!creation && (
        <button className="suprim-whitelabel" onClick={onOpenModaleDelete}>
          <DeleteOutlinedIcon />
          Delete product
        </button>
      )}
    </>
  );
}
