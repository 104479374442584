import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modale } from "../../../../../components/Modale";
import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import {
  createListing,
  deleteListingById,
  updateListingById,
} from "../../../../../modules/taxiModuleListings";
import { fetchAllListings } from "../../../../../store/TaxiModuleListing/features";
import { toast } from "react-toastify";

export const ModuleListingModal = ({
  isOpen,
  setIsOpen,
  selectedListing,
  setSelectedListing,
  ...props
}) => {
  const [listing, setListing] = useState(selectedListing || { title: "" });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (listing.title.length < 3)
      return toast.error("You should enter a title with at least 3 characters");

    setLoading(true);
    try {
      if (selectedListing) {
        await updateListingById(listing);
      }
      if (!selectedListing) {
        await createListing(listing);
      }
      setListing({ title: "" });
      setIsOpen(false);
      dispatch(fetchAllListings());
      if (selectedListing) setSelectedListing(null);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    await deleteListingById(selectedListing.id);
    dispatch(fetchAllListings());
    setIsOpen(false);
    setSelectedListing(null);
    setListing({ title: "" });
  };

  useEffect(() => {
    if (selectedListing) setListing(selectedListing);
  }, [selectedListing]);

  return (
    <Modale
      title={selectedListing ? "Edit the listing" : "Create a listing"}
      close={() => {
        setSelectedListing(null);
        setListing({ title: "" });
        setIsOpen(false);
      }}
      actions={
        selectedListing
          ? [
              {
                title: "Submit",
                click: onSubmit,
                disabled: loading,
              },
              {
                title: "Delete",
                click: onDelete,
                disabled: loading,
              },
            ]
          : [
              {
                title: "Submit",
                click: onSubmit,
                disabled: loading,
              },
            ]
      }
      isOpen={isOpen}
      {...props}
    >
      <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
        Title
        <Input
          type="text"
          id="title"
          placeholder="Enter a title for this listing"
          value={listing.title}
          onChange={(e) => setListing({ ...listing, title: e.target.value })}
          name="title"
        />
      </LabelInput>
    </Modale>
  );
};
