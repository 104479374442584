import * as ReactLeaflet from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, MapConsumer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const Map = ({ children, className, ...rest }) => {
  return (
    <MapContainer {...rest}>
      <MapConsumer>{(map) => children(ReactLeaflet, map)}</MapConsumer>
    </MapContainer>
  );
};

export default Map;
