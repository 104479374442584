import { useEffect } from "react";
import {
  Input,
  LabelInput,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Calendar } from "../../../../../components/Calendar";
import Radio from "../../../../../components/Radio";
import { useSelector, useDispatch } from "react-redux";
import { whitelabelListSelector } from "../../../../../store/Whitelabel/WhitelabelList/index";
import { fetchWhitelabelList } from "../../../../../store/Whitelabel/WhitelabelList/features";
import { WhitelabelSelection } from "../../../../../components/WhitelabelSelection";

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

const Calendars = styled.div`
  margin: 8px 0;
  display: flex;
  gap: 8px;
  > * {
    width: calc(50% - 4px);
  }
`;
const WrapperInputBudget = styled.div`
  margin: 8px 0;
  display: flex;
  gap: 8px;
  > * {
    width: calc(50% - 4px);
  }
`;

export function CampaignSettings({
  selectedCampaign,
  setSelectedCampaign,
  handleSaveCampaign,
  createCampaign,
}) {
  const navigate = useNavigate();
  const {
    whiteLabelList,
    isLoading,
    selectedWhiteLabel: filterWhitelabel,
  } = useSelector(whitelabelListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWhitelabelList());
  }, []);

  useEffect(() => {
    if (createCampaign && selectedCampaign.whitelabel) {
      const findLayout = whiteLabelList.find(
        (whitelabel) => whitelabel.slug === selectedCampaign.whitelabel
      );
      setSelectedCampaign({
        ...selectedCampaign,
        whitelabelLayout: findLayout.layout,
      });
    }
    if (createCampaign && !selectedCampaign.whitelabel) {
      setSelectedCampaign({
        ...selectedCampaign,
        whitelabelLayout: "",
      });
    }
  }, [selectedCampaign.whitelabel]);

  return (
    <>
      {createCampaign && (
        <>
          <WhitelabelSelection
            value={selectedCampaign?.whitelabel}
            onChange={(newValue) =>
              setSelectedCampaign({
                ...selectedCampaign,
                whitelabel: newValue,
              })
            }
          />
          <LabelInput htmlFor="userId">
            User id
            <Input
              type="userId"
              id="userId"
              placeholder="Enter a user id"
              value={selectedCampaign?.userId || ""}
              onChange={(e) =>
                setSelectedCampaign({
                  ...selectedCampaign,
                  userId: e.target.value,
                })
              }
              name="name"
            />
          </LabelInput>
        </>
      )}
      <LabelInput htmlFor="name">
        Name
        <Input
          type="name"
          id="name"
          placeholder="Enter a name"
          value={selectedCampaign?.name || ""}
          onChange={(e) =>
            setSelectedCampaign({
              ...selectedCampaign,
              name: e.target.value,
            })
          }
          name="name"
        />
      </LabelInput>
      <Radio
        id={"option1"}
        title={"Set dates"}
        type={"radio"}
        value={true}
        checked={selectedCampaign?.campaignDate}
        onChange={(e) =>
          setSelectedCampaign({
            ...selectedCampaign,
            campaignDate: e.target.checked,
          })
        }
      />
      <LabelInput htmlFor="name">
        Duration
        <Calendars>
          <Calendar
            label={"Start"}
            value={selectedCampaign?.startDate}
            onChange={(date) =>
              setSelectedCampaign({
                ...selectedCampaign,
                startDate: date,
              })
            }
          />
          <Calendar
            label={"End"}
            value={selectedCampaign?.endDate}
            onChange={(date) =>
              setSelectedCampaign({
                ...selectedCampaign,
                endDate: date,
              })
            }
          />
        </Calendars>
      </LabelInput>
      <Radio
        id={"option2"}
        title={"Start now with no end Date"}
        type={"radio"}
        value={false}
        checked={!selectedCampaign?.campaignDate}
        onChange={(e) =>
          setSelectedCampaign({
            ...selectedCampaign,
            campaignDate: !e.target.checked,
          })
        }
      />
      <WrapperInputBudget>
        <LabelInput htmlFor="dailyBudget">
          Daily Budget
          <Input
            type="number"
            id="dailyBudget"
            placeholder="Enter a daily budget"
            value={selectedCampaign?.dailyBudget || 0}
            onChange={(e) =>
              setSelectedCampaign({
                ...selectedCampaign,
                dailyBudget: Number(e.target.value),
              })
            }
            name="dailyBudget"
          />
        </LabelInput>
        <LabelInput htmlFor="totalBudget">
          Total budget
          <Input
            type="number"
            id="totalBudget"
            placeholder="Enter a daily budget"
            value={selectedCampaign?.totalBudget || 0}
            onChange={(e) =>
              setSelectedCampaign({
                ...selectedCampaign,
                totalBudget: Number(e.target.value),
              })
            }
            name="totalBudget"
          />
        </LabelInput>
      </WrapperInputBudget>
      {!createCampaign && (
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis?tab=campaigns")}>
            Back
          </Button>
          <Button onClick={() => handleSaveCampaign()}>Save</Button>
        </Buttons>
      )}
    </>
  );
}
