import { createSlice } from "@reduxjs/toolkit";
import { fetchAllProgramFidelity } from "./features";

export const programFidelityList = createSlice({
  name: "programFidelityList",
  initialState: {
    programFidelityList: [],
    isLoading: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProgramFidelity.fulfilled, (state, { payload }) => {
        state.programFidelityList = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllProgramFidelity.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllProgramFidelity.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default programFidelityList.reducer;

export const programFidelityListSelector = (state) => state.programFidelityList;