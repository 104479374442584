import { Button, CardContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { deleteModuleById } from "../../../../../modules/taxiModules";
import { fetchTaxiModules } from "../../../../../store/TaxiModules/features";
import { fetchTaxiWidgets } from "../../../../../store/TaxiWidgets/features";
import {
  LabelInput,
  Input,
  Select,
} from "../../../../../components/ModalWhiteLabel/ModalWhiteLabelStyle";
import { CheckBox } from "../../../../../components/CheckBox";
import { format } from "date-fns";
import { fetchAllListings } from "../../../../../store/TaxiModuleListing/features";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal";

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: no-wrap;
  font-weight: bold;
`;

const Fields = styled.div`
  width: 50%;
`;

const Buttons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
`;

export function ModuleEdit({
  selectedModule,
  setSelectedModule,
  handleSaveModule,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const widgets = useSelector((state) => state.taxiWidgets.value);
  const listings = useSelector((state) => state.moduleListing.listings);
  const handleDeleteModule = async () => {
    await deleteModuleById(selectedModule.id);
    dispatch(fetchTaxiModules());
    setIsDeleteModalOpen(false);
    navigate("/dashboard/taxis?tab=modules");
  };

  useEffect(() => {
    dispatch(fetchTaxiWidgets());
    dispatch(fetchAllListings());
  }, []);

  return (
    <>
      <ConfirmationModal
        title="Delete Module"
        content={`Do you realy want to delete «${selectedModule.title}» ?`}
        isOpen={isDeleteModalOpen}
        close={() => setIsDeleteModalOpen(false)}
        onConfirmation={async () => {
          handleDeleteModule();
        }}
      />
      <CardContent>
        <Container>
          <Fields>
            <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
              Title
              <Input
                type="text"
                id="title"
                placeholder="Enter a title for this module"
                value={selectedModule.title}
                onChange={(e) =>
                  setSelectedModule({
                    ...selectedModule,
                    title: e.target.value,
                  })
                }
                name="title"
              />
            </LabelInput>
            <LabelInput
              style={{ minWidth: "300px" }}
              htmlFor="widget"
              hidden={selectedModule.type !== "widget"}
            >
              Widget
              <Select
                onChange={(e) =>
                  setSelectedModule({
                    ...selectedModule,
                    widgetId: e.target.value,
                  })
                }
                value={selectedModule.widgetId || widgets[0]?.id}
                name="widget"
              >
                {widgets.map((widget, index) => {
                  return (
                    <option key={index} value={widget.id}>
                      {widget.name}
                    </option>
                  );
                })}
              </Select>
            </LabelInput>
            <LabelInput
              style={{ minWidth: "300px" }}
              htmlFor="listing"
              hidden={selectedModule.widgetId !== "PvW30tMLOdE2KqbIvJPk"}
            >
              Listing
              <Select
                onChange={(e) =>
                  setSelectedModule({
                    ...selectedModule,
                    listingId: e.target.value,
                  })
                }
                value={selectedModule.listingId}
                name="listing"
              >
                <option disabled selected>
                  Select a list
                </option>
                {listings.map((listing, index) => {
                  return (
                    <option key={index} value={listing.id}>
                      {listing.title}
                    </option>
                  );
                })}
              </Select>
            </LabelInput>
            <LabelInput
              style={{ minWidth: "300px" }}
              htmlFor="url"
              hidden={
                selectedModule.type !== "qrcode" &&
                selectedModule.widgetId !== "qnbmHbFeRjaQ7zSNc8N9"
              }
            >
              URL
              <Input
                type="url"
                id="url"
                placeholder="Enter an url"
                value={selectedModule.url}
                onChange={(e) =>
                  setSelectedModule({ ...selectedModule, url: e.target.value })
                }
                name="url"
              />
            </LabelInput>
            <CheckBox
              title="Active"
              checked={selectedModule.active}
              onChange={(e) =>
                setSelectedModule({
                  ...selectedModule,
                  active: e.target.checked,
                })
              }
            />
          </Fields>
          <Fields>
            <LabelInput style={{ minWidth: "300px" }} htmlFor="name">
              Type
              <Select name="type" disabled={true} value={selectedModule.type}>
                <option value={"qrcode"}>QR Code</option>
                <option value={"widget"}>Widget</option>
              </Select>
            </LabelInput>

            <LabelInput style={{ minWidth: "300px" }} htmlFor="title">
              Last update
              <Input
                type="text"
                id="lastUpdate"
                value={format(selectedModule.updatedAt, "yyyy/MM/dd")}
                name="title"
                disabled={true}
              />
            </LabelInput>
          </Fields>
        </Container>
        <Buttons>
          <Button onClick={() => navigate("/dashboard/taxis?tab=modules")}>
            Back
          </Button>
          <Button onClick={() => setIsDeleteModalOpen(true)}>Delete</Button>
          <Button onClick={() => handleSaveModule()}>Save</Button>
        </Buttons>
      </CardContent>
    </>
  );
}
