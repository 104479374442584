import { createSlice } from "@reduxjs/toolkit";
import { fetchTaxiModuleRates } from "./features";

export const rates = createSlice({
  name: "rates",
  initialState: {
    rates: [],
    isLoading: true,
    isError: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxiModuleRates.fulfilled, (state, { payload }) => {
        state.rates = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchTaxiModuleRates.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchTaxiModuleRates.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default rates.reducer;

export const ratesSelector = (state) => state.rates;
