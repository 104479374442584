import { createSlice } from "@reduxjs/toolkit";
import { fetchBusinessCardTemplates } from "./features";

export const businessCardTemplates = createSlice({
  name: "businessCardTemplates",
  initialState: {
    value: [],
    loading: true,
    error: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessCardTemplates.fulfilled, (state, { payload }) => {
        state.value = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchBusinessCardTemplates.pending, (state, { payload }) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchBusinessCardTemplates.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default businessCardTemplates.reducer;

export const businessCardTemplatesSelector = (state) =>
  state.businessCardTemplates;
